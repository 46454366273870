import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import BackendService from '../../services/backend'
import _ from 'lodash'
import {
  Cascader,
  Radio,
  Spin,
  Form,
  Select,
  Switch,
  Modal,
  Typography,
  Steps,
  Row,
  Col,
  Button,
} from 'antd'
import styles from './Modal.module.scss'
import { RootState } from '../../@types'
const { Text, Link } = Typography
const { Step } = Steps
const { Option } = Select
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}
const labelCol = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 5 } }
const wrapperCol = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 15, offset: 1 } }

type CreateProjectModalProps = {
  showModal: boolean
  timeperiod: any
  setshowModal: React.Dispatch<React.SetStateAction<boolean>>
  retailersgrouped: any
  setRetailersgrouped: React.Dispatch<React.SetStateAction<any>>
  loading1: boolean
}
type Basecall = {
  id: string
  name: string
}
export const CreateProjectModal: React.FC<CreateProjectModalProps> = props => {
  const { showModal, setshowModal, retailersgrouped, setRetailersgrouped, loading1, timeperiod } =
    props
  const history = useHistory()
  const cognitoUser = useSelector((state: RootState) => state.auth.cognitoUser)
  const [basecallpoint, setBaseCall] = useState<any[]>([])
  const [basecallexactValue, setBasecallexactValue] = useState<string>()
  const [store, setStore] = useState<number>(0)
  const [step, setstep] = useState(0)
  const [index, setIndex] = useState<number>(1)
  const [basecallkey, setBasecallkey] = useState<number>(1)
  const [retailerid, setRetailerID] = useState<string>('')
  const [cascader, setCascader] = useState<number>(0)
  const [options, setOptions] = React.useState(retailersgrouped)
  const [projectTime, setProjectTime] = useState<string>()
  const [exactCascader, setExactCascader] = useState<any>([])
  const [retailername, setRetailername] = useState<string>('Please Select')
  const [status, setStatus] = useState<string>('')
  const [selectedbasecall, setSelectedBase] = useState<Basecall[]>([{ id: '', name: '' }])
  const [retailerSelect, setRetailerSelect] = useState('')
  const [baseselected, setisBaseSelected] = useState(false)
  const [loading, setloading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [isMulti, setIsMulti] = useState(false)
  const [form] = Form.useForm()

  const getBaseCallPoint = (reqid: string) => {
    setloading(true)
    BackendService.getBaseCallPoint(reqid).then(data => {
      const sorted = data.data?.sort((a: any, b: any) =>
        a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())
      )
      const modified = sorted?.map((item: any) => ({
        label: item.name,
        value: item.name,
        key: item.basecallpointid,
      }))
      setBaseCall(modified)
      setloading(false)
    })
  }
  const handleCancel = () => {
    setStatus('')
    setRetailername('Please Select')
    setCascader(0)
    setProjectTime(undefined)
    setRetailerID('')
    setBasecallexactValue(undefined)
    setSelectedBase([{ id: '', name: '' }])
    setisBaseSelected(false)
    setshowModal(false)
    setIndex(index + 1)
    setstep(0)
  }

  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  const yyyy = String(today.getFullYear()).substring(2)
  const todays = mm + dd + yyyy
  const projname = `Recap_${String(selectedbasecall.map(a => a.name))
    .replace(/,/g, '_')
    .replace(/ /g, '_')}_${String(retailerSelect).replace('/', '_').replace(/ /g, '')}_${String(
    projectTime
  ).replace(/ /g, '_')}_${status}_${todays}`
  const projNameMulti = `Recap_${retailerid?.split('#')[1]?.replace(/ /g, '_')}_${String(
    projectTime
  ).replace(/ /g, '_')}_${status}_${todays}`
  const submitForm2 = () => {
    if (!cognitoUser) return
    setConfirmLoading(true)
    setloading(true)
    BackendService.createProject(
      isMulti ? projNameMulti?.toUpperCase() : projname?.toUpperCase(),
      projectTime ? projectTime : '',
      retailerid,
      isMulti ? [{ id: '', name: '' }] : selectedbasecall,
      isMulti,
      cascader,
      status
    )
      .then(data => {
        setloading(false)
        setshowModal(false)
        setConfirmLoading(false)
        history.push(`projectplanogram/${data.projectid}/uploads`)
      })
      .catch(err => {
        console.log('error', err)
        setloading(false)
      })
  }
  const onCascaderChange = (value: any, selectedOptions: any) => {
    console.log('🚀  ~ selectedOptions', selectedOptions)
    if (selectedOptions.length == 2) {
      const retailname = String(value).replace(/,/g, '#')
      setRetailername(retailname)
      setBasecallexactValue(undefined)
      setBasecallkey(basecallkey + value[1])
      setRetailerID(retailname)
      const encodeURI = encodeURIComponent(String(value).replace(/,/g, '#').replace(/\//g, ''))
      getBaseCallPoint(encodeURI)
    }
  }
  const chl = new Array(50).fill(50).map((_, i) => ({ value: i + 4, label: `Desc ${i + 1}` }))

  const storeExtraction = [
    {
      value: 'From File Name',
      label: 'From File Name',
      children: [
        {
          value: 1,
          label: 'Use PSA File Name',
        },
        {
          value: 2,
          label: 'Use Planogram Project Name',
        },
        {
          value: 3,
          label: 'Use Planogram Name',
        },
      ],
    },
    {
      value: 'Use Planogram Description Field',
      label: 'Use Planogram Description Field',
      children: chl,
    },
  ]
  const storename = (() => {
    if (cascader === 1) {
      return 'From File Name / Use PSA File Name'
    } else if (cascader === 2) {
      return 'From File Name / Use Planogram Project Name'
    } else if (cascader === 3) {
      return 'From File Name / Use Planogram Name'
    } else if (cascader === 4) {
      return 'Use Planogram Description Field / Desc 1'
    } else if (cascader === 5) {
      return 'Use Planogram Description Field / Desc 2'
    } else if (cascader === 6) {
      return 'Use Planogram Description Field / Desc 3'
    } else if (cascader === 7) {
      return 'Use Planogram Description Field / Desc 4'
    } else if (cascader === 8) {
      return 'Use Planogram Description Field / Desc 5'
    } else if (cascader === 9) {
      return 'Use Planogram Description Field / Desc 6'
    } else if (store === 10) {
      return 'Use Planogram Description Field / Desc 7'
    } else if (cascader === 11) {
      return 'Use Planogram Description Field / Desc 8'
    } else if (cascader === 12) {
      return 'Use Planogram Description Field / Desc 9'
    } else if (cascader === 13) {
      return 'Use Planogram Description Field / Desc 10'
    } else {
      return 'Please Select Store Extraction'
    }
  })()
  const loadData = (selectedOptions: any) => {
    setRetailerSelect(selectedOptions[0]?.value)
    const encodeURI = encodeURIComponent(selectedOptions[0].id)
    console.log('🚀 ~ file: Modal.tsx ~ line 212 ~ loadData ~ encodeURI', encodeURI)
    const targetOption = selectedOptions[selectedOptions.length - 1]
    console.log('🚀 ~ file: Modal.tsx ~ line 213 ~ loadData ~ targetOption', targetOption)
    targetOption.loading = true
    // load options lazily
    setloading(true)
    setTimeout(() => {
      BackendService.getRetailerNames(encodeURI).then(data => {
        const sorted = data?.data.sort((a: any, b: any) =>
          a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())
        )
        const modified = sorted?.map((item: any) => ({
          id: item.id,
          label: item.name,
          value: item.id,
          // pId: key,
          // isLeaf: false,
        }))
        targetOption.children = modified
        targetOption.loading = false
        setOptions([...options])
        setloading(false)
      })
    }, 500)
  }
  const onLoadData = ({ key, e }: any) => {
    setloading(true)
    const encodeURI = encodeURIComponent(key)
    return new Promise<void>(resolve => {
      BackendService.getRetailerNames(encodeURI).then(data => {
        const sorted = data?.data.sort((a: any, b: any) =>
          a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())
        )
        const modified = sorted?.map((item: any) => ({
          id: item.id,
          title: item.name,
          value: item.id,
          pId: key,
          isLeaf: true,
        }))
        setRetailersgrouped([...retailersgrouped, ...modified])
        setloading(false)
        resolve()
      })
    })
  }

  const onBaseSelect = (e: any, key: any) => {
    const array = []
    const obj = {}
    obj['id'] = key?.key
    obj['name'] = key?.label
    array.push(obj)
    const modified = array?.map((item: any) => ({
      id: item.id,
      name: item.name,
    }))
    setBasecallexactValue(e)
    setSelectedBase(modified)
    setisBaseSelected(true)
  }
  const next = () => {
    setstep(step + 1)
  }
  const prev = () => {
    setstep(step - 1)
  }
  const cascaderChange = (e: any, key: any) => {
    setExactCascader(e)
    setCascader(e[1])
  }
  const timePeriodChange = (e: any) => {
    setProjectTime(e)
  }
  const setStatusChange = (e: any) => {
    setStatus(e.target.value)
  }
  const RetailCascader = (
    <>
      <Form
        name='basic'
        {...layout}
        colon={false}
        preserve={false}
        style={{ marginTop: '40px' }}
        form={form}
        className={styles.form}
        scrollToFirstError
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        hideRequiredMark
      >
        <Form.Item
          label='Select Retailer'
          name='retailID'
          rules={[{ required: true, message: 'Please Select Retailer' }]}
        >
          <Cascader
            placeholder={retailername.replace(/#/g, '/')}
            notFoundContent={!loading1 ? <Spin size='small' /> : 'No Data'}
            options={retailersgrouped}
            loadData={loadData}
            onChange={onCascaderChange}
          />
        </Form.Item>
        <Form.Item label='MultiBase Project' valuePropName='checked'>
          <Switch
            onChange={(e: any) => {
              setIsMulti(e)
              if (!e) {
                setBasecallexactValue(undefined)
              }
            }}
          />
        </Form.Item>
        {!isMulti ? (
          <Form.Item
            key={basecallkey}
            label='Base Call Points'
            name='baseCallPoint'
            rules={[{ required: true, message: 'Please Select Basecall Point' }]}
          >
            <Select
              // mode='multiple'
              showSearch
              placeholder='Select Basecall Points'
              allowClear
              // value={selected}
              defaultValue={basecallexactValue}
              notFoundContent={loading ? <Spin size='small' /> : 'No Data'}
              onChange={onBaseSelect}
              options={basecallpoint}
            ></Select>
          </Form.Item>
        ) : null}
      </Form>
    </>
  )

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }
  const Timeperiod = (
    <div>
      <Form
        name='basic'
        {...layout}
        colon={false}
        preserve={false}
        style={{ marginTop: '40px' }}
        form={form}
        className={styles.form}
        scrollToFirstError
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        hideRequiredMark
      >
        <Form.Item
          label='Store Extraction'
          name='slkey'
          rules={[{ required: true, message: `${String(exactCascader)}` }]}
        >
          <Cascader
            placeholder={String(storename)}
            options={storeExtraction}
            onChange={cascaderChange}
          />
        </Form.Item>
        <Form.Item
          label='Time Period'
          name='projTime'
          rules={[{ required: true, message: 'Please select a Time Period' }]}
        >
          <Select
            placeholder='Select a Option'
            allowClear
            onChange={timePeriodChange}
            defaultValue={projectTime}
          >
            {timeperiod.map((item: any, i: any) => (
              <Option key={i} value={item.dis}>
                {item.dis}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Set Status'
          name='setStatus'
          rules={[{ required: true, message: 'Please select a Status' }]}
        >
          <Radio.Group onChange={setStatusChange} defaultValue={status}>
            <Radio value='Final'>Final</Radio>
            <Radio value='Proposed'>Proposed</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </div>
  )
  const Projectname = (
    <>
      <Form
        name='basic'
        style={{ marginTop: '40px' }}
        {...formItemLayout}
        // labelCol={{ span: 12 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
      >
        <Form.Item label='Project Name' name='projectName' style={{ marginBottom: '5px' }}>
          {isMulti ? (
            <Text style={{ fontSize: '17px', textTransform: 'uppercase' }}>
              {/* {console.log('ret', retailerid)} */}
              Recap_
              {/* {String(selectedbasecall.map(a => a.name))
                .replace(/ /g, '_')
                .replace(/,/g, '_')} */}
              {retailerid?.split('#')[1]?.replace(/ /g, '_')}_
              {String(retailerSelect).replace('/', '_').replace(/ /g, '')}_
              {String(projectTime).replace(/ /g, '_')}_{status}_{todays}
            </Text>
          ) : (
            <Text style={{ fontSize: '17px', textTransform: 'uppercase' }}>
              Recap_
              {String(selectedbasecall.map(a => a.name))
                .replace(/ /g, '_')
                .replace(/,/g, '_')}
              _{String(retailerSelect).replace('/', '_').replace(/ /g, '_')}_
              {String(projectTime).replace(/ /g, '_')}_{status}_{todays}
            </Text>
          )}
        </Form.Item>
        <Form.Item label='Retailer' name='retail' style={{ marginBottom: '5px' }}>
          <Text style={{ fontSize: '17px' }}>{retailerid.replace(/#/g, '/')} </Text>
        </Form.Item>

        <Form.Item label='Basecall Points' name='base' style={{ marginBottom: '5px' }}>
          {!isMulti ? (
            <Text style={{ fontSize: '17px' }}>
              {String(selectedbasecall.map(a => a.name)).replace(/,/g, '_')}{' '}
            </Text>
          ) : (
            <Text style={{ fontSize: '17px' }}>Multi Base Project</Text>
          )}
        </Form.Item>

        <Form.Item label='Store Extraction' name='srextr' style={{ marginBottom: '5px' }}>
          <Text style={{ fontSize: '17px' }}>{storename} </Text>
        </Form.Item>
        <Form.Item label='Time Period' name='timeperiod' style={{ marginBottom: '5px' }}>
          <Text style={{ fontSize: '17px' }}>{projectTime} </Text>
        </Form.Item>
        <Form.Item label='Status' name='status' style={{ marginBottom: '5px' }}>
          <Text style={{ fontSize: '17px' }}>{status} </Text>
        </Form.Item>
      </Form>
    </>
  )
  const determineContent = (step: number) => {
    if (step === 0) return RetailCascader
    if (step == 1) return Timeperiod
    if (step == 2) return Projectname
  }
  return (
    <Modal
      key={index}
      title={'Create Project'}
      style={{ top: 20, height: 800 }}
      visible={showModal}
      width={800}
      maskClosable={false}
      closable={false}
      keyboard={false}
      footer={[
        step <= 2 && (
          <>
            <Button style={{ margin: '0 8px' }} onClick={() => handleCancel()}>
              Cancel
            </Button>
          </>
        ),
        step > 0 && (
          <>
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Prev
            </Button>
          </>
        ),
        step == 1 && (
          <>
            {projectTime && cascader && status ? (
              <Button type='primary' style={{ margin: '0 8px' }} onClick={() => next()}>
                Next
              </Button>
            ) : (
              <Button type='primary' disabled>
                Next
              </Button>
            )}
          </>
        ),
        step == 0 && (
          <>
            {!isMulti ? (
              baseselected && retailerid ? (
                <Button type='primary' style={{ margin: '0 8px' }} onClick={() => next()}>
                  Next
                </Button>
              ) : (
                <Button type='primary' disabled>
                  Next
                </Button>
              )
            ) : retailerid ? (
              <Button type='primary' style={{ margin: '0 8px' }} onClick={() => next()}>
                Next
              </Button>
            ) : (
              <Button type='primary' disabled>
                Next
              </Button>
            )}
          </>
        ),
        step === 2 && (
          <>
            {projectTime && retailerid && selectedbasecall && cascader && status ? (
              <Button
                type='primary'
                style={{ margin: '0 8px' }}
                onClick={() => submitForm2()}
                loading={confirmLoading}
              >
                Submit
              </Button>
            ) : (
              <Button type='primary' disabled>
                Submit
              </Button>
            )}
          </>
        ),
        // step == 2 && (
        //   <>
        //     <Button style={{ margin: '0 8px' }} onClick={() => submitForm2()}>
        //       Submit
        //     </Button>
        //   </>
        // ),
      ]}
    >
      <Row className={styles.row}>
        <Col span={24}>
          <Steps type='navigation' current={step}>
            <Step title={'Select Retailers'} disabled={loading} />
            <Step title={'Project Settings'} disabled={loading} />
            <Step title={'Review'} disabled={loading} />
          </Steps>
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col span={24}>{determineContent(step)}</Col>
      </Row>
    </Modal>
  )
}
