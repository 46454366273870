import { LiteType, ILiteState, SetLiteDashAction, TotalLiteActionTypes } from '../../@types'
import * as actionTypes from '../action/actionTypes'

const initialState: ILiteState = null

interface ILiteReducer<A> {
  (state: ILiteState, action: A): ILiteState
}

const setLiteData: ILiteReducer<SetLiteDashAction> = (state, action) => {
  return {
    ...state,
    ...action.data,
  } as LiteType
}

const reducer = (state: ILiteState = initialState, action: TotalLiteActionTypes): ILiteState => {
  switch (action.type) {
    case actionTypes.SET_LITE:
      return setLiteData(state, action)
    default:
      return state
  }
}

export default reducer
