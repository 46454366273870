import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useHistory, Link } from 'react-router-dom'
import { Form, Button, Input, Row, Checkbox, Typography, message, notification, Space } from 'antd'
import { SignIn } from '../../../services'
import { setCognitoUser } from '../../../store/action/index'
import { CognitoUserExt, RootState } from '../../../@types'
import styles from './style.module.scss'
import { Auth } from 'aws-amplify'
import { resetPassword, updateUser } from '../../../services'
import { Popover, Progress } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { Rule } from 'antd/lib/form'

const { Text, Paragraph } = Typography

const FormItem = Form.Item
type LoginType = {
  mail: string
  password: string
  FamilyName: string
  GivenName: string
}
type ResetPwdType = {
  password: string
  newPassword: string
}
type LocationType = {
  username: string
  password: string
  cognitoUser: any
}
type Function = {
  username: string
  code: string
  newPassword: string
}

const ChangePassword = () => {
  const location = useLocation<LocationType>()
  const [visible, setvisible] = useState(false)
  const [popover, setpopover] = useState(false)
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('$%')
  const [firstName, setFirstName] = useState<any>('')
  const [secondName, setSecondName] = useState<any>('')
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const [form] = Form.useForm()
  const [check, setcheck] = useState(true)
  const [loading, setloading] = useState(false)
  const cognitoUser = useSelector((state: RootState) => state.auth.cognitoUser)
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((res: any) => {
      setFirstName(res?.attributes?.family_name)
      setSecondName(res?.attributes?.given_name)
    })
  }, [])
  const onFinish = async (values: ResetPwdType) => {
    resetPassword(values.password, values.newPassword)
  }
  const passwordLengthValidator = (rule: Rule, value: string) => {
    const promise = Promise
    if (!value) {
      setvisible(!!value)
      return promise.reject(t('user.required.password'))
    }
    if (!visible) {
      setvisible(!!value)
    }
    setpopover(!popover)
    if (value.length < 8) return promise.reject('')
    return promise.resolve()
  }

  const passwordCapLetterValidator = (rule: Rule, value: string) => {
    const promise = Promise
    setpopover(!popover)
    if (value === value.toLowerCase()) return promise.reject('')
    return promise.resolve()
  }

  const passwordLowLetterValidator = (rule: Rule, value: string) => {
    const promise = Promise
    setpopover(!popover)
    if (value === value.toUpperCase()) return promise.reject('')
    return promise.resolve()
  }

  const passwordNumberValidator = (rule: Rule, value: string) => {
    const promise = Promise
    setpopover(!popover)
    if (/\d/.test(value)) return promise.resolve()
    return promise.reject('')
  }

  const checkPsswordLength = () => {
    const password = form.getFieldValue('newPassword')
    if (password.length < 8) return false
    return true
  }

  const checkPsswordCapLetter = () => {
    const password = form.getFieldValue('newPassword')
    if (password === password.toLowerCase()) return false
    return true
  }

  const checkPsswordLowLetter = () => {
    const password = form.getFieldValue('newPassword')
    if (password === password.toUpperCase()) return false
    return true
  }

  const checkPsswordNumber = () => {
    const password = form.getFieldValue('newPassword')
    return /\d/.test(password)
  }

  const getPasswordStatus = () => {
    const password = form.getFieldValue('newPassword')
    if (
      checkPsswordLength() &&
      checkPsswordCapLetter() &&
      checkPsswordLowLetter() &&
      checkPsswordNumber() &&
      password.length > 11
    )
      return 'success'
    if (
      checkPsswordLength() &&
      checkPsswordCapLetter() &&
      checkPsswordLowLetter() &&
      checkPsswordNumber()
    )
      return 'normal'
    return 'exception'
  }

  const passwordStatusMap = {
    success: <div className={styles.success}>{t('user.strength.strong')}</div>,
    normal: <div className={styles.warning}>{t('user.strength.medium')}</div>,
    exception: <div className={styles.error}>{t('user.strength.invalid')}</div>,
  }

  const renderPasswordProgress = () => {
    const password = form.getFieldValue('newPassword')
    const passwordStatus = getPasswordStatus()
    return password && password.length ? (
      <div>
        <Progress
          status={passwordStatus}
          className={styles.progress}
          strokeWidth={8}
          percent={passwordStatus === 'exception' ? 0 : passwordStatus === 'normal' ? 50 : 100}
          showInfo={false}
        />
      </div>
    ) : null
  }

  const genPopoverContent = () =>
    visible && (
      <div className={styles.popover}>
        {passwordStatusMap[getPasswordStatus()]}
        {renderPasswordProgress()}
        <div className={styles.popoverContent}>
          <Row align='middle'>
            {checkPsswordLength() ? (
              <CheckCircleTwoTone className={styles.popoverIcon} twoToneColor='#52c41a' />
            ) : (
              <CloseCircleTwoTone className={styles.popoverIcon} twoToneColor='#f5222d' />
            )}
            {t('user.hint.length')}
          </Row>
          <Row align='middle'>
            {checkPsswordLowLetter() ? (
              <CheckCircleTwoTone className={styles.popoverIcon} twoToneColor='#52c41a' />
            ) : (
              <CloseCircleTwoTone className={styles.popoverIcon} twoToneColor='#f5222d' />
            )}
            {t('user.hint.lowLetter')}
          </Row>
          <Row align='middle'>
            {checkPsswordCapLetter() ? (
              <CheckCircleTwoTone className={styles.popoverIcon} twoToneColor='#52c41a' />
            ) : (
              <CloseCircleTwoTone className={styles.popoverIcon} twoToneColor='#f5222d' />
            )}
            {t('user.hint.capLetter')}
          </Row>
          <Row align='middle'>
            {checkPsswordNumber() ? (
              <CheckCircleTwoTone className={styles.popoverIcon} twoToneColor='#52c41a' />
            ) : (
              <CloseCircleTwoTone className={styles.popoverIcon} twoToneColor='#f5222d' />
            )}
            {t('user.hint.number')}
          </Row>
        </div>
      </div>
    )
  const update = () => {
    Auth.currentAuthenticatedUser().then((res: CognitoUserExt) => {
      dispatch(setCognitoUser(res))
    })
  }
  const changeFirstname = (e: any) => {
    setFirstName(e)
    updateUser(cognitoUser, {
      family_name: e,
    }).then(() => {
      update()
    })
  }
  const changeSecondname = (e: any) => {
    setSecondName(e)
    updateUser(cognitoUser, {
      given_name: e,
    }).then(() => {
      update()
    })
  }

  return (
    <div className={styles.main}>
      <h2>User Details</h2>
      <Form form={form} name='UserDetails' autoComplete='off'>
        <Row>
          <Text strong>First Name :</Text>
          <Paragraph
            style={{ marginLeft: '12px' }}
            editable={{ onChange: e => changeFirstname(e) }}
          >
            {firstName}
          </Paragraph>
        </Row>
        <Row>
          <Text strong>Last Name :</Text>
          <Paragraph
            style={{ marginLeft: '12px' }}
            editable={{ onChange: e => changeSecondname(e) }}
          >
            {secondName}
          </Paragraph>
        </Row>
      </Form>
      <h2>Reset Password</h2>

      <Form form={form} name='Newpassword' onFinish={onFinish} autoComplete='off'>
        <Row>Old Password</Row>

        <FormItem name='password' className={styles.password}>
          <Input.Password size='large' type='password' placeholder={'Type Old Password'} />
        </FormItem>
        <Row>New Password</Row>
        <Popover
          content={genPopoverContent}
          overlayStyle={{ width: 300 }}
          placement='right'
          visible={visible}
        >
          <FormItem
            name='newPassword'
            className={styles.password}
            rules={[
              {
                validator: passwordLengthValidator,
              },
              {
                validator: passwordCapLetterValidator,
              },
              {
                validator: passwordLowLetterValidator,
              },
              {
                validator: passwordNumberValidator,
              },
            ]}
          >
            <Input.Password
              onChange={e => setNewPassword(e.target.value)}
              size='large'
              type='password'
              placeholder={'Type New Password'}
            />

            {/* <Input size='large' type='password' placeholder={t('user.required.password')} /> */}
          </FormItem>
        </Popover>
        <Row>Confirm New Password</Row>

        <FormItem name='ConfirmNewPassword' className={styles.password}>
          <Input.Password
            onChange={e => setConfirmNewPassword(e.target.value)}
            size='large'
            type='password'
            placeholder={'Type Old Password'}
          />
          {newPassword === confirmNewPassword ? null : (
            <Text type='danger'>Password must be same</Text>
          )}
        </FormItem>
        <FormItem className={styles.submit}>
          <Button
            size='large'
            loading={loading}
            disabled={newPassword === confirmNewPassword ? false : true}
            className={styles.submit}
            type='primary'
            htmlType='submit'
          >
            Submit
          </Button>
        </FormItem>
      </Form>
    </div>
  )
}

export default ChangePassword
