import { TotalCount, ICountState, SetCountDataAction, TotalCountActionTypes } from '../../@types'
import * as actionTypes from '../action/actionTypes'

const initialState: ICountState = null

interface ICountReducer<A> {
  (state: ICountState, action: A): ICountState
}

const setTotalCountData: ICountReducer<SetCountDataAction> = (state, action) => {
  return {
    ...state,
    ...action.data,
  } as TotalCount
}

const reducer = (state: ICountState = initialState, action: TotalCountActionTypes): ICountState => {
  switch (action.type) {
    case actionTypes.SET_TOTALCOUNT:
      return setTotalCountData(state, action)
    default:
      return state
  }
}

export default reducer
