import React from 'react'
import { ConfigProvider } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from './@types'
import enUS from 'antd/es/locale/en_US'
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'

const localeSwitch = {
  'zh-CN': zhCN,
  'en-US': enUS,
}
moment.locale('en-US')

const AntdConfig: React.FC = props => {
  const locale = useSelector((state: RootState) => state.locale.locale)

  const validateMessages = {
    'zh-CN': {
      required: 'Required',
      types: {
        number: 'Required number',
      },
      number: {
        // eslint-disable-next-line no-template-curly-in-string
        min: 'Cannot be less than${min}',
        // eslint-disable-next-line no-template-curly-in-string
        max: 'Cannot be greater than${max}',
        // eslint-disable-next-line no-template-curly-in-string
        range: 'gotta be${min}Until${max}区间内',
      },
    },
    'en-US': {
      required: 'required Field',
      types: {
        number: 'number is required',
      },
      number: {
        // eslint-disable-next-line no-template-curly-in-string
        min: 'cannot be less than ${min}',
        // eslint-disable-next-line no-template-curly-in-string
        max: 'cannot be greater than ${max}',
        // eslint-disable-next-line no-template-curly-in-string
        range: 'must be between ${min} and ${max}',
      },
    },
  }

  return (
    <ConfigProvider
      locale={localeSwitch[locale]}
      form={{ validateMessages: validateMessages[locale] }}
    >
      {props.children}
    </ConfigProvider>
  )
}

export default AntdConfig
