import Dexie, { Table } from 'dexie'
import {
  PlanogramProducts,
  ProjectPlanogram,
  ControlTable,
  Hierarchy,
  ProjectStores,
  TempTable,
} from './Models'

export class RevealDB extends Dexie {
  projectproducts: Table<PlanogramProducts, number>
  projectstores: Table<ProjectStores, number>
  projectplanogram: Table<ProjectPlanogram, number>
  controlltable: Table<ControlTable, number>
  productstore: Table<PlanogramProducts, number>
  hierarchy: Table<Hierarchy, number>
  // temptable: Table<TempTable, number>
  constructor() {
    super('RevealDB')
    this.version(1).stores({
      projectproducts:
        '[projectid+UPC],Category,ProductManufacturer,SubCategory,Package,Segment,Subsegment',
      projectstores: '[projectid+storenumber]',
      projectplanogram: '[projectid+pogkey]',
      controlltable: '[projectid+object]',
      productstore: '[projectid+storenumber+UPC]',
      hierarchy: '[projectid+object+objectkey+objectvalue]',
      // temptable: '[projectid+masterupc+storenumber]',
    })
    this.projectproducts = this.table('projectproducts')
    this.projectplanogram = this.table('projectplanogram')
    this.projectstores = this.table('projectstores')
    this.controlltable = this.table('controlltable')
    this.hierarchy = this.table('hierarchy')
    this.productstore = this.table('productstore')
    // this.temptable = this.table('temptable')
  }
}
export const db = new RevealDB()
