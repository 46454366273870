import {
  AdminUser,
  IAdminUserState,
  SetAdminUserDataAction,
  AdminUserActionTypes,
} from '../../@types'
import * as actionTypes from '../action/actionTypes'

const initialState: IAdminUserState = null

interface IAdminUserReducer<A> {
  (state: IAdminUserState, action: A): IAdminUserState
}

const setAdminUserData: IAdminUserReducer<SetAdminUserDataAction> = (state, action) => {
  return {
    ...state,
    ...action.data,
  } as AdminUser
}

const reducer = (
  state: IAdminUserState = initialState,
  action: AdminUserActionTypes
): IAdminUserState => {
  switch (action.type) {
    case actionTypes.SET_ADMIN_USER:
      return setAdminUserData(state, action)
    default:
      return state
  }
}

export default reducer
