import React from 'react'
import JSZip from 'jszip'
// import { saveAs } from 'file-saver'

export default function zipTargetFiles(data: any, uploadType: any) {
  const zip = new JSZip()

  // zip.file(data.name, data)
  data.map((fl: any) => {
    zip?.file(fl?.name, fl)
  })
  if (uploadType == 'PDF') {
    return zip.generateAsync({
      type: 'blob',
    })
  } else {
    return zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: {
        level: 6,
      },
    })
  }
}
