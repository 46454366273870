import Auth from '@aws-amplify/auth'

const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AUTH_REGION,
    identityPoolId: 'us-east-1:ca0cc1fc-a050-4d4c-b7df-7f5009ee3ea0',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'blob-images',
        endpoint: 'https://j88z3cghz3.execute-api.us-east-1.amazonaws.com/',
        region: 'us-east-1',
        custom_header: async () => {
          // Alternatively, with Cognito User Pools use this:
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Content-Type': 'application/json',
          }
        },
      },
      {
        name: 'reveal2',
        endpoint: 'https://cn4z4dnm7e.execute-api.us-east-1.amazonaws.com/',
        region: 'us-east-1',
        custom_header: async () => {
          // Alternatively, with Cognito User Pools use this:
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Content-Type': 'application/json',
          }
        },
      },
      {
        name: 'revealnext2',
        endpoint: 'https://qjgrd7u1k8.execute-api.us-east-1.amazonaws.com/',
        region: 'us-east-1',
        custom_header: async () => {
          // Alternatively, with Cognito User Pools use this:
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'Content-Type': 'application/json',
          }
        },
      },
    ],
  },
  Storage: {
    region: 'us-east-1',
    bucket: 'cmsfiles173752-staging',
    aws_user_pools_id: 'us-east-1_9iurl9wCh',
  },
}

export default config
