import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'
import { Form, Button, Input, Row, Checkbox, notification } from 'antd'
import { SignIn } from '../../../services'
import { setCognitoUser } from '../../../store/action/index'
import styles from './style.module.scss'
import { db } from '../../../data/db'
import { Redirect } from 'react-router-dom'

const FormItem = Form.Item

type LoginType = {
  mail: string
  password: string
}

const Login: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [check, setcheck] = useState(true)
  const [loading, setloading] = useState(false)
  const redirectOnLogin = () => history.push('/user/newpwd')
  const onFinish = (values: LoginType) => {
    setloading(true)
    SignIn({ username: values.mail, password: values.password })
      .then(cognitoUser => {
        const isApp = () => {
          let checkApp
          if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            checkApp =
              cognitoUser.challengeParam.userAttributes.attributes['custom:apps'].includes(
                'revealnext'
              )
          } else {
            checkApp = cognitoUser.attributes['custom:apps'].includes('revealnext')
          }
          return checkApp
        }
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const requiredAttributes = cognitoUser.challengeParam
          // console.log(cognitoUser, 'cognitoUser')
          history.push({
            pathname: '/user/newpwd',
            state: {
              username: values.mail,
              password: values.password,
              cognitoUser: requiredAttributes,
            },
          })

          return
          // return redirectOnLogin()
        } else if (!isApp) {
          setloading(false)
          history.push({
            pathname: '/accessdenied',
          })
        } else {
          setloading(false)
          return new Promise<void>(resolve => {
            dispatch(setCognitoUser(cognitoUser))
            resolve()
          }).then(() => {
            // console.log('hai1')
            try {
              db.open()
            } catch (e) {
              console.log('database has been closed')
            }
            history.push('/')
          })
        }
      })

      // .catch(cognitoUser => {
      // if (cognitoUser.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
      //   history.push({
      //     pathname: '/user/newpwd',
      //     state: { username: values.mail, password: values.password },
      //   })
      //   return
      // } else {
      //     notification.error({
      //       message: t('user.error.login'),
      //       description: t(`user.error.${cognitoUser.code}`),
      //     })
      //     return
      //   }
      // })
      .catch(err => {
        setloading(false)
        if (err.code === 'UserNotConfirmedException') {
          history.push({
            pathname: '/user/verify',
            state: { username: values.mail, password: values.password },
          })
          // console.log('hai2')
          return
        } else {
          notification.error({
            message: t('user.error.login'),
            description: t(`user.error.${err.code}`),
          })
          // console.log('hai3')
          return
        }
      })
  }

  return (
    <div className={styles.main}>
      <h2>{t('user.login.welcome')}</h2>
      <Form form={form} name='Login' onFinish={onFinish}>
        <Row>{t('user.required.email')}</Row>
        <FormItem
          name='mail'
          rules={[
            {
              required: true,
              message: t('user.required'),
            },
            {
              type: 'email',
              message: t('user.format.email'),
            },
          ]}
        >
          <Input size='large' placeholder={t('user.placeholder.email')} />
        </FormItem>
        <Row>{t('user.required.password')}</Row>
        <FormItem
          name='password'
          className={styles.password}
          rules={[
            {
              required: true,
              message: t('user.required'),
            },
          ]}
        >
          <Input.Password size='large' type='password' placeholder={t('user.required.password')} />
          {/* {/ <Input.Password /> /} */}
          {/* <Input size='large' type='password' placeholder={t('user.required.password')} /> */}
        </FormItem>
        <Checkbox className={styles.floatleft} checked={check} onChange={() => setcheck(!check)}>
          {t('user.save-login')}
        </Checkbox>
        <Link className={styles.floatright} to='/user/forget'>
          {t('user.forget-password')}
        </Link>
        <FormItem className={styles.submit}>
          <Button
            size='large'
            loading={loading}
            className={styles.submit}
            type='primary'
            htmlType='submit'
          >
            {t('user.login')}
          </Button>
        </FormItem>
        {/* <Link className={styles.floatright} to='/user/register'>
          {t('user.to-sign-up')}
        </Link> */}
      </Form>
    </div>
  )
}

export default Login
