import React, { useState, useEffect, useRef } from 'react'
import { Steps, Skeleton, Badge, Tooltip, Modal } from 'antd'
import styles from './uploadXl.module.scss'
import {
  SyncOutlined,
  DownloadOutlined,
  FileTwoTone,
  ShareAltOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import { FileList, TableLite, DownloadData, FolderList } from '../../@types'
import { ColumnsType } from 'antd/lib/table'
import { Table, Button, Row, Col, Card, Progress, Select } from 'antd'
import BackendService from '../../services/backend'
import dayjs from 'dayjs'
import { SearchOutlined } from '@ant-design/icons'
import { SearchString } from '../../components/Table/TableColFilters/TableColSearch'
import { setIsLiteDash } from '../../store/action'
import { useSelector, useDispatch } from 'react-redux'
import { AppstoreOutlined } from '@ant-design/icons'
import axios from 'axios'
import ReportLinkDataDisplay from './ReportLinkDisplay'
type UploadTableProps = {
  tableData: TableLite[]
  loading: boolean
  setData: React.Dispatch<React.SetStateAction<TableLite[]>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  // setIndex: React.Dispatch<React.SetStateAction<number>>
  fetchTableData: () => void
  projId: string
  // setisSup: React.Dispatch<React.SetStateAction<boolean>>
}
const UploadliteTable: React.FC<UploadTableProps> = props => {
  const [showModal, setshowModal] = useState(false)
  const [LinkDetails, setLinkDetails] = useState({})
  const { projId, tableData, fetchTableData, loading, setLoading, setData } = props
  const [linkLoading, setLinkLoad] = useState(false)
  const dispatch = useDispatch()
  const [dData, setDData] = useState<DownloadData[]>([])
  const download = (filename: any, proj: string) => {
    console.log('🚀 ~ file: ListDataset.tsx ~ line 85 ~ download ~ filename', filename)

    const documentStyles = document.documentElement.style
    let progress = 0

    // setProgress('in-progress')

    const options = {
      onDownloadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent
        progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        // console.log('progress on download', progress)
        // setPercentage(progress)
        if (progress == 100) {
          // setStart(false)
        }
      },
    }
    // url: data[0].link, console.log('link', data[0].link)
    // console.log(' dData[0].link', dData[0]?.link)
    BackendService.getExtractFile(proj).then(data => {
      setDData(data.data)
      setLoading(false)
      axios({
        url: data.data[0].link,
        responseType: 'blob',
        ...options,
      }).then(response => {
        // console.log(response)

        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        )

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        //   // setProgress('finished')
      })
    })
  }

  const getProjectLite = (prj: any) => {
    setLoading(true)
    BackendService.getProjectLite(prj).then((res: any) => {
      const singleProj = res.Item
      const dataArr = tableData
      const objIndex = dataArr.findIndex((obj: any) => obj.projectid == singleProj.projectid)
      if (dataArr[objIndex] && singleProj.status === 'complete') {
        dataArr[objIndex].setStatus = singleProj.status
        setData(dataArr)
      }
      // dataArr[objIndex].setStatus = 'processing'
      // setData(dataArr)
      setLoading(false)
    })
  }

  const generateLink = (record?: any) => {
    BackendService.generateReportLink(record.projectid, record.dashboardId).then((res: any) => {
      console.log(res)
      setLinkDetails({ ...res, projectidSliced: record.projectid })
    })
  }
  const tableReqCol: ColumnsType<TableLite> = [
    {
      title: 'Created At / Completed At',
      dataIndex: 'createdAt',
      fixed: 'left',
      render: (val: string, record: any) => (
        <div>
          {dayjs(val).format('MM-DD-YYYY h:mm A')} /<br />
          {record.updatedAt ? dayjs(record.updatedAt).format('MM-DD-YYYY h:mm A') : 'Not Complete'}
        </div>
      ),
      sorter: (a: TableLite, b: TableLite) => a.createdAt.localeCompare(b.createdAt),
      defaultSortOrder: 'descend',
      // render: (val: number) => new Date(val * 1000).toLocaleString(),
      width: 100,
    },
    {
      title: 'Project Name',
      dataIndex: 'projName',
      // sorter: (a: TableLite, b: TableLite) => a.filetype?.localeCompare(b.filetype),
      fixed: 'left',
      width: 100,
      ...SearchString('projName'),
      render: (val: string, record: any) => val,
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.projName?.toString().toLowerCase().includes(value.toString().toLowerCase()),
    },
    // {
    //   title: 'Updated / Completed At',
    //   dataIndex: 'updateddate',
    //   render: (val: string) => (val ? dayjs(val).format('MM-DD-YYYY h:mm A') : 'Not Complete'),
    //   defaultSortOrder: 'descend',
    //   width: 200,
    // },
    {
      title: 'File Count',
      dataIndex: 'fileCount',
      width: 125,
      // sorter: (a, b) => a.filecount - b.filecount,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 80,
      // sorter: (a: TableLite, b: TableLite) => a.status.localeCompare(b.status),
      render: (val: any) => (
        <>
          {val == 'processing' ? (
            <Badge status='warning' text='Processing' />
          ) : val == 'pending' ? (
            <Badge status='warning' text='Pending' />
          ) : (
            <Badge status='success' text={val} />
          )}
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'dashboardId',
      width: 125,
      // sorter: (a, b) => a.fileerrored - b.fileerrored,
      render: (text, record) =>
        record.status == 'complete' ? (
          <>
            <Tooltip placement='bottom' title={'View Report'}>
              <Button
                type='primary'
                shape='round'
                onClick={() => {
                  dispatch(setIsLiteDash({ isDash: true, dashboardId: record.dashboardId }))
                  // setisSup(true)
                  // getProjectLite(record.projectid)
                }}
                // loading={linkLoading}
                icon={<AppstoreOutlined />}
              ></Button>
            </Tooltip>{' '}
            <Tooltip placement='bottom' title={'Download'}>
              <Button
                type='primary'
                shape='round'
                icon={
                  <DownloadOutlined
                    onClick={() => {
                      // setStart(true)
                      download(`${record.projName}.csv`, record.projectid)
                    }}
                  />
                }
              />
            </Tooltip>{' '}
            <Tooltip placement='bottom' title={'Share Report Link'}>
              <Button
                type='primary'
                shape='round'
                onClick={() => {
                  setshowModal(true)
                  console.log(record)
                  console.log(LinkDetails)
                  generateLink(record)
                }}
                // loading={linkLoading}
                icon={<ShareAltOutlined />}
              ></Button>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip placement='bottom' title={'Refresh'}>
              <Button
                type='primary'
                shape='round'
                onClick={() => {
                  getProjectLite(record.projectid)
                }}
                loading={linkLoading}
                icon={<SyncOutlined />}
              ></Button>
            </Tooltip>
          </>
        ),
    },
  ]

  return (
    <div>
      <Modal
        title='Share Dashboard'
        visible={showModal}
        footer={null}
        onCancel={() => {
          setshowModal(false)
          setLinkDetails({})
        }}
      >
        <ReportLinkDataDisplay {...LinkDetails} />
      </Modal>
      <Card className={styles.row}>
        <Button
          className={styles.rightBut}
          shape='round'
          onClick={fetchTableData}
          // loading={loading}
          icon={<SyncOutlined />}
          ghost
          type='primary'
        >
          {'Refresh'}
        </Button>
        <Table
          dataSource={tableData}
          columns={tableReqCol}
          rowKey='createdAt'
          loading={loading}
          pagination={{
            position: ['bottomCenter'],
            // total: tableData.length,
            showTotal: total => `${total}` + ' items',
            defaultPageSize: 100,
            showSizeChanger: true,
          }}
          scroll={{ x: 'max-content' }}
        />
      </Card>
    </div>
  )
}

export default UploadliteTable

const rowData = [
  {
    link: 'fvdsuvfbdhsdjbvcd.com',
  },
  {
    link: 'dwuiomsixaoixmo.com',
  },
]
