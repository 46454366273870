import { embedDashboard } from '@preset-sdk/embedded'
import BackendService from '../../services/backend'
import React, { useEffect } from 'react'
import './analytic.css'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../@types'

const Super = () => {
  const lite = useSelector((state: RootState) => state.liteDash)
  const fetchGuestTokenFromBackend = async () => {
    const reslt = BackendService.getDashboardLite(lite?.dashboardId).then(res => {
      return res.token
    })
    return reslt
  }

  useEffect(() => {
    const el2 = document.getElementById('superset-container')
    const dashboardId = lite?.dashboardId

    if (el2 && dashboardId) {
      embedDashboard({
        id: dashboardId,
        supersetDomain: 'https://dashboard.revealform.com',
        mountPoint: document.getElementById('analytic2') as HTMLElement,
        fetchGuestToken: async () => fetchGuestTokenFromBackend(),
        dashboardUiConfig: { hideChartControls: false, hideTitle: true, hideTab: false },
      }).catch((error: any) => {
        console.log('error', error)
      })
    }
  }, [])
  return (
    <>
      <main className='MainAnalytic1'>
        <div className='analytic' id='analytic2' style={{ width: '100%' }} />
      </main>
    </>
  )
}

export default Super
