import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Layout, Menu, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../assets/logo-no-text.png'
import PrivateHeader from '../PrivateHeader/PrivateHeader'
import PublicHeader from '../PublicHeader/PublicHeader'
import DefaultFooter from '../Footer/DefaultFooter'
import EmailSupport from '../../components/TechSupport/EmailSupport'
import styles from './BasicLayout.module.scss'
import { RootState } from '../../@types'
import Auth from '@aws-amplify/auth'

const { Sider, Content } = Layout
const { Footer } = Layout
const { SubMenu } = Menu

const BasicLayout: React.FC = props => {
  const history = useHistory()
  // console.log('🚀 ~ file: BasicLayout.tsx ~ line 22 ~ history', loc)
  // console.log('w', window.location.href)
  const { t } = useTranslation()
  const cognitoUser = useSelector((state: RootState) => state.auth.cognitoUser)
  const selectMenu = history.location.pathname.split('/')[1]
  const [userType, setUserType] = useState('standard')
  const [app, setApp] = useState('')
  const GetadminUser = async () => {
    const user = await Auth.currentAuthenticatedUser()
    setApp(user?.attributes?.['custom:tenant'])
    // console.log(' ~ user', user?.attributes?.['custom:tenant'])
    if (user.signInUserSession?.accessToken?.payload?.['cognito:groups']?.[0] == undefined) {
      setUserType('standard')
    } else {
      setUserType(user.signInUserSession?.accessToken?.payload['cognito:groups']?.[0])
    }
  }
  useEffect(() => {
    GetadminUser()
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='description' content={t('user.logo.welcome')} />
        <title>{t('sider.company')}</title>
      </Helmet>
      <Layout>
        {/* <EmailSupport /> */}
        <Sider width={250} className={styles.sider}>
          <Row className={styles.title} align='middle' justify='center'>
            <img alt='logo' className={styles.logo} src={logo} />
            <div>
              <h1>{t('sider.company')}</h1>
              <h4>{t('sider.edition')}</h4>
            </div>
          </Row>
          <Menu
            theme='dark'
            mode='inline'
            selectedKeys={[selectMenu || 'dashboard']}
            onSelect={({ key }) =>
              key === 'dashboard' ? history.push('/') : history.push(`/${key}`)
            }
          >
            <Menu.Item key='dashboard' className={styles.menuItem}>
              {'Dashboard'}
            </Menu.Item>
            {app === 'site' || window.location.href.includes('app.revealnext') ? (
              <>
                {/* <Menu.Item key='manageproject' className={styles.menuItem}>
                  Manage Projects
                </Menu.Item> */}
                <Menu.Item key='reveallite' className={styles.menuItem}>
                  Reveal Lite
                </Menu.Item>
              </>
            ) : (
              <>
                <Menu.Item key='manageproject' className={styles.menuItem}>
                  Manage Projects
                </Menu.Item>
                {window.location.href.split('.')[0].includes('bbc') ? null : (
                  <>
                    <Menu.Item key='reveallite' className={styles.menuItem}>
                      Reveal Lite
                    </Menu.Item>
                  </>
                )}
                {userType === 'admin' && (
                  <>
                    <SubMenu key='adminmenu' title='Admin Menu'>
                      <Menu.Item key='masterdata' className={styles.menuItem}>
                        Master Data
                      </Menu.Item>
                      <Menu.Item key='manageretailers' className={styles.menuItem}>
                        Manage Retailers
                      </Menu.Item>
                      <Menu.Item key='managetimeperiod' className={styles.menuItem}>
                        Manage TimePeriod
                      </Menu.Item>
                      <Menu.Item key='manageuser' className={styles.menuItem}>
                        Manage Users
                      </Menu.Item>
                    </SubMenu>
                  </>
                )}
              </>
            )}
          </Menu>
        </Sider>
        <Layout className={styles.main}>
          {cognitoUser ? <PrivateHeader /> : <PublicHeader />}
          <Content className={styles.content}>{props.children}</Content>
          <Footer className={styles.footer}>
            <DefaultFooter />
          </Footer>
        </Layout>
      </Layout>
    </>
  )
}

export default BasicLayout
