import * as actionTypes from './actionTypes'
import { ThunkAction } from 'redux-thunk'
import { RootState, LiteType, TotalLiteActionTypes } from '../../@types'

type ThunkResult<R> = ThunkAction<R, RootState, undefined, TotalLiteActionTypes>
interface IAction<P, T> {
  (param: P): ThunkResult<T>
}
export const setIsLiteDash: IAction<Partial<LiteType>, void> = data => dispatch => {
  dispatch({
    type: actionTypes.SET_LITE,
    data: data,
  })
}
