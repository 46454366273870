/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import { Button, Table, Tag, Select, Tabs, Typography, Menu } from 'antd'
import { Row, Space, Dropdown, message, Input, AutoComplete } from 'antd'
import dayjs from 'dayjs'
import { SyncOutlined, SearchOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { SearchString } from '../../components/Table/TableColFilters/TableColSearch'
import { CreateProjectModal } from './Modal'
import styles from './ProjectTable.module.scss'
import { PlanogramTable, RootState, UserType } from '../../@types'
import { ColumnsType } from 'antd/lib/table'
import Auth from '@aws-amplify/auth'
import _ from 'lodash'
import BackendService from '../../services/backend'
import { useDispatch, useSelector } from 'react-redux'
import { setAdminUserData } from '../../store/action/index'

const { TabPane } = Tabs

const { Option } = Select
const ProjectTable: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentProj = useSelector((state: RootState) => state.adminuser)
  const [userid, setUserid] = useState<string>()
  const [data, setdata] = useState<PlanogramTable[]>([])
  const [initialData, setInitialData] = useState<PlanogramTable[]>([])
  const [archiveData, setArchiveData] = useState<PlanogramTable[]>([])

  const [loading, setloading] = useState(false)
  const [archiveloading, setArchiveloading] = useState(false)

  const [showModal, setshowModal] = useState(false)
  const [retailers, setRetailers] = useState<any[]>([])
  const [retailersgrouped, setRetailersgrouped] = useState<any[]>([])
  const [userType, setUserType] = useState('standard')
  const [user, setUser] = useState()
  // const [userid, setUserId] = useState('')
  const [UsrName, setUsrName] = useState('')
  const [UserData, setUserData] = useState<UserType[]>([])
  const [timeperiod, setTimeperiod] = useState<any>([])
  const [defaultTimeperiod, setDefaultTimeperiod] = useState<string>('')
  const archiveOnClick = (record: any) => {
    // console.log('record :>> ', record)
  }
  const getTimeperiod = () => {
    BackendService.getTimePeriod().then(res => {
      const activeTimeperiod = res[0]?.period.filter((item: any) => item.isActive == 1)
      const defaultTimeperiod = res[0]?.period.filter((item: any) => item.isDefault == 1)
      const sorted = activeTimeperiod.sort((a: any, b: any) => a.sortOrder - b.sortOrder)
      setTimeperiod(sorted)
      setDefaultTimeperiod(defaultTimeperiod.map((item: any) => item.dis))
    })
  }
  const onClick = (key: any) => {
    const projectid = key?.key.slice(0, -1)
    if (key?.key.slice(-1) == 1) {
      setdata(data.filter(item => item.projectid != projectid))
      makeArchive(projectid)
    } else if (key?.key.slice(-1) == 2) {
      console.log('Edit')
    } else if (key?.key.slice(-1) == 3) {
      console.log('Delete')
    } else if (key?.key.slice(-1) == 4) {
      archiveData
      setArchiveData(archiveData.filter(item => item.projectid != projectid))
      makeActive(projectid)
    } else null
  }
  const menu = (record: any) => (
    <Menu onClick={onClick}>
      <Menu.Item key={record.projectid + '1'}>Make Archive</Menu.Item>
      {/* <Menu.Item key={record.projectid + '2'}>Edit</Menu.Item>
      <Menu.Item key={record.projectid + '3'}>Delete</Menu.Item> */}
    </Menu>
  )
  const menuArchive = (record: any) => (
    <Menu onClick={onClick}>
      <Menu.Item key={record.projectid + '4'}>Make Active</Menu.Item>
      {/* <Menu.Item key={record.projectid + '2'}>Edit</Menu.Item>
      <Menu.Item key={record.projectid + '3'}>Delete</Menu.Item> */}
    </Menu>
  )
  const tableCols: ColumnsType<PlanogramTable> = [
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      fixed: 'left',
      render: (val: string) => dayjs(val).format('MM-DD-YYYY h:mm A'),
      sorter: (a: PlanogramTable, b: PlanogramTable) => a?.createdAt?.localeCompare(b?.createdAt),
      defaultSortOrder: 'descend',
      width: 180,
    },
    {
      title: t('project.create.title'),
      dataIndex: 'projName',
      key: 'projName',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.projName?.localeCompare(b.projName),
      fixed: 'left',
      width: 300,
      ...SearchString('projName'),
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.projName.toString().toLowerCase().includes(value.toString().toLowerCase()),
    },

    {
      title: 'Call Points',
      dataIndex: 'co',
      key: 'co',
      // sorter: (a: PlanogramTable, b: PlanogramTable) => a.co?.localeCompare(b.co),
      fixed: 'left',
      // width: 200,
      ...SearchString('ClassofTrade'),
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.co?.toString().toLowerCase().includes(value.toString().toLowerCase()),
      render: (value, record) => (
        <>
          {record.co}
          <br />
          {record.retailName}
          <br />
          {record?.multiBase && (
            <>
              <span>MultiBase Project</span>
              <br />
            </>
          )}
          {record.basecCallPoints?.map((tag: any) => {
            return (
              <div key={tag.id}>
                <Tag>{tag.name} </Tag>
              </div>
            )
          })}
        </>
      ),
    },
    {
      title: ' Time Period',
      dataIndex: 'projectTime',
      key: 'projectTime',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.projectTime?.localeCompare(b.projectTime),
      width: 150,
    },
    {
      title: 'Set Status',
      dataIndex: 'setStatus',
      key: 'setStatus',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.setStatus?.localeCompare(b.setStatus),
      width: 100,
    },
    {
      title: 'Total Planograms',
      dataIndex: 'pogCount',
      key: 'pogCount',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.pogCount - b.pogCount,
      width: 100,
    },
    {
      title: 'Total Products',
      dataIndex: 'prodCount',
      key: 'prodCount',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.prodCount - b.prodCount,
      width: 100,
    },
    {
      title: 'Total Stores',
      dataIndex: 'storeCount',
      key: 'storeCount',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.storeCount - b.storeCount,
      width: 100,
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'tags',
      width: 100,
      render: tags => (
        <div>
          {(() => {
            if (tags == 'In-Progress') {
              return <Tag color={'green'}>{tags}</Tag>
            } else {
              return <Tag color={'geekblue'}>{tags}</Tag>
            }
          })()}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      render: (text, record, index) => (
        <Dropdown overlay={menu(record)}>
          <Button type='text' style={{ fontWeight: 'bold' }}>
            . . .
          </Button>
        </Dropdown>
        // <Button type='primary' onClick={() => archiveOnClick(record)}>
        //   Make Archive
        // </Button>
      ),
    },
  ]
  const tableColsArchive: ColumnsType<PlanogramTable> = [
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      fixed: 'left',
      render: (val: string) => dayjs(val).format('MM-DD-YYYY h:mm A'),
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.createdAt.localeCompare(b.createdAt),
      defaultSortOrder: 'descend',
      width: 180,
    },
    {
      title: t('project.create.title'),
      dataIndex: 'projName',
      key: 'projName',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.projName.localeCompare(b.projName),
      fixed: 'left',
      width: 300,
      ...SearchString('projName'),
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.projName.toString().toLowerCase().includes(value.toString().toLowerCase()),
    },

    {
      title: 'Call Points',
      dataIndex: 'co',
      key: 'co',
      // sorter: (a: PlanogramTable, b: PlanogramTable) => a.co?.localeCompare(b.co),
      fixed: 'left',
      // width: 200,
      ...SearchString('ClassofTrade'),
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.co?.toString().toLowerCase().includes(value.toString().toLowerCase()),
      render: (value, record) => (
        <>
          {record.co}
          <br />
          {record.retailName}
          <br />
          {record.basecCallPoints?.map((tag: any) => {
            return (
              <div key={tag.id}>
                <Tag>{tag.name} </Tag>
              </div>
            )
          })}
        </>
      ),
    },
    {
      title: ' Time Period',
      dataIndex: 'projectTime',
      key: 'projectTime',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.projectTime?.localeCompare(b.projectTime),
      width: 150,
    },
    {
      title: 'Set Status',
      dataIndex: 'setStatus',
      key: 'setStatus',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.setStatus?.localeCompare(b.setStatus),
      width: 100,
    },
    {
      title: 'Total Planograms',
      dataIndex: 'pogCount',
      key: 'pogCount',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.pogCount - b.pogCount,
      width: 100,
    },
    {
      title: 'Total Products',
      dataIndex: 'prodCount',
      key: 'prodCount',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.prodCount - b.prodCount,
      width: 100,
    },
    {
      title: 'Total Stores',
      dataIndex: 'storeCount',
      key: 'storeCount',
      sorter: (a: PlanogramTable, b: PlanogramTable) => a.storeCount - b.storeCount,
      width: 100,
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'tags',
      width: 100,
      render: tags => (
        <div>
          {(() => {
            if (tags == 'In-Progress') {
              return <Tag color={'green'}>{tags}</Tag>
            } else {
              return <Tag color={'geekblue'}>{tags}</Tag>
            }
          })()}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      render: (text, record, index) => (
        <Dropdown overlay={menuArchive(record)}>
          <Button type='text' style={{ fontWeight: 'bold' }}>
            . . .
          </Button>
        </Dropdown>
        // <Button type='primary' onClick={() => archiveOnClick(record)}>
        //   Make Archive
        // </Button>
      ),
    },
  ]
  // Manually trigger update Archive list data
  const fetchData = async (usrid: string) => {
    setloading(true)
    BackendService.fetchProjects(usrid).then(data => {
      setdata(data)
      setInitialData(data)
      setloading(false)
    })
  }
  const fetchArchiveData = async (usrid: string) => {
    setArchiveloading(true)
    BackendService.fetchArchiveProjects(usrid).then(data => {
      setArchiveData(data)
      setArchiveloading(false)
    })
  }

  const GetadminUser = async () => {
    const user = await Auth.currentAuthenticatedUser()
    if (currentProj == null || currentProj.id == undefined) {
      // console.log('🚀  If ~ currentProj', currentProj)
      fetchData(user.username)
      fetchArchiveData(user.username)
    } else {
      // console.log('🚀  Else ~ currentProj', currentProj)
      fetchData(currentProj.id)
      fetchArchiveData(currentProj.id)
    }
    setUsrName('')
    setUserid(undefined)
    setUserType(user.signInUserSession.accessToken.payload?.['cognito:groups']?.[0])
    // dispatch(setAdminUserData({ id: user.username }))
  }
  const fetchData1 = async () => {
    setloading(true)
    BackendService.getRetailers().then(data => {
      // setRetailers(
      //   data.data.sort((a: any, b: any) =>
      //     a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())
      //   )
      // )
      // const sorted = data?.data.sort((a: any, b: any) =>
      //   a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())
      // )
      const sortednumber = data?.data.sort((a: any, b: any) => b.count - a.count)
      const retailerTitle = sortednumber?.map((x: any) => ({
        id: x.id,
        label: `${x.name}`,
        value: `${x.name}`,
        isLeaf: false,
        selectable: false,
      }))
      // console.log('retailerTitle :>> ', retailerTitle)
      // const grouped = _.groupBy(data.data, 'type')
      // const iterateL2 = Object.entries(grouped).map((item: any) => {
      //   return {
      //     label: item[0],
      //     key: item[0],
      //     options: item[1].map((items: any) => {
      //       return {
      //         label: items.name,
      //         value: `${items.retailerid}#${items.name}`,
      //         key: items.retailerid,
      //       }
      //     }),
      //   }
      // })
      setRetailersgrouped(retailerTitle)
    })
    setloading(false)
  }
  const getUserData = async () => {
    const userList = await BackendService.getUserList()
    const filterUser = userList.filter((element: any) => element?.apps?.includes('revealnext'))
    const sortByName = [...filterUser].sort((a, b) => (a.name > b.name ? 1 : -1))

    if (window.location.href.split('.')[0].includes('bbc')) {
      const bbcuser = sortByName.filter((element: any) => element.name !== 'Demo')
      setUserData(bbcuser)
    } else {
      setUserData(sortByName)
    }
  }
  // Automatically obtain form data after component rendering
  useEffect(() => {
    GetadminUser()
    getUserData()
  }, [])
  const handlemockUserDataChange = (value: any, key: any) => {
    setUserid(key.key)
    setUsrName(value)
    setloading(true)
    dispatch(setAdminUserData({ id: key.key, name: value }))
    fetchData(key.key)
    fetchArchiveData(key.key)
  }
  const buttonOnclick = () => {
    fetchData1()
    getTimeperiod()
    setshowModal(true)
  }
  const refreshClicked = async () => {
    const user = await Auth.currentAuthenticatedUser()
    fetchData(user.username)
    fetchArchiveData(user.username)
  }
  const makeArchive = async (projectid: string) => {
    setArchiveloading(true)
    const user = await Auth.currentAuthenticatedUser()
    BackendService.makeArchive(projectid).then(data => {
      // fetchArchiveData(user.username)
      message.success(`${data.projName} Moved to Archive`)
    })
  }
  const makeActive = async (projectid: string) => {
    setloading(true)
    const user = await Auth.currentAuthenticatedUser()
    BackendService.makeActive(projectid).then(data => {
      if (currentProj == null || currentProj.id == undefined) {
        fetchData(user.username)
      } else {
        fetchData(currentProj.id)
      }
      message.success(`${data.projName} Moved to Active`)
    })
  }
  const onTabChange = async (e: any) => {
    if (!userid) {
      const user = await Auth.currentAuthenticatedUser()
      // console.log('no userid :>> ', user.username)
      if (e == 2) {
        fetchArchiveData(user.username)
      }
    } else {
      // const user = await Auth.currentAuthenticatedUser()
      console.log('userid :>> ', userid)

      if (e == 2) {
        fetchArchiveData(userid)
      }
    }
  }

  const validData = data
  const validData2 = archiveData
  const tempArr: any[] = []
  // const datas = initialData.forEach(item =>
  //   item.basecCallPoints.forEach((subItem: any) => tempArr.push(subItem.name))
  // )

  return (
    <>
      <div style={{ padding: '20px 12px' }}>
        <Button className={styles.leftBut} type='primary' size='large' onClick={buttonOnclick}>
          {t('project.create-project')}
        </Button>
        <>
          <Space>
            <Typography style={{ paddingLeft: 10 }}>Show Project:</Typography>
            <Select
              style={{ width: 150 }}
              onChange={handlemockUserDataChange}
              value={currentProj?.name}
              defaultValue={currentProj?.name}
              placeholder='Select By User'
              // loading={UserData.length ? false : true}
            >
              {UserData.map(usr => (
                <Option key={usr.userid} value={usr.name}>
                  {usr.name}
                </Option>
              ))}
            </Select>
            {/* {UsrName !== '' && (
                <CloseOutlined
                  onClick={() => {
                    dispatch(setAdminUserData({ id: '', name: '' }))
                    GetadminUser()
                  }}
                />
              )} */}
          </Space>
        </>

        <Button
          className={styles.rightBut}
          shape='round'
          loading={loading}
          icon={<SyncOutlined />}
          onClick={refreshClicked}
          ghost
          type='primary'
        >
          {t('action.refresh')}
        </Button>
        <CreateProjectModal
          showModal={showModal}
          timeperiod={timeperiod}
          setshowModal={setshowModal}
          retailersgrouped={retailersgrouped}
          setRetailersgrouped={setRetailersgrouped}
          loading1={loading}
        />
      </div>
      <Row style={{ marginBottom: '20px' }}>
        <Space>
          {/* <AutoComplete dataSource={initialData.map(person => person.projName)}>
            <Input.Search
              allowClear
              enterButton
              placeholder={'Filter by Name'}
              onSearch={nameSearch =>
                setdata(initialData.filter(person => person.projName.includes(nameSearch)))
              }
            />
          </AutoComplete> */}
          <AutoComplete dataSource={[...new Set(initialData.map(person => person.co))]}>
            <Input.Search
              allowClear
              enterButton
              placeholder={'Filter by Class of Trade'}
              onSearch={nameSearch =>
                setdata(initialData.filter(person => person.co.includes(nameSearch)))
              }
            />
          </AutoComplete>
          {/* <AutoComplete dataSource={[...new Set(initialData.map(person => person.retailName))]}>
            <Input.Search
              allowClear
              enterButton
              placeholder={'Filter by Retail Name'}
              onSearch={nameSearch =>
                setdata(initialData.filter(person => person.retailName.includes(nameSearch)))
              }
            />
          </AutoComplete>
          {tempArr ? (
            <AutoComplete dataSource={[...new Set(tempArr)].map((person, i) => person)}>
              <Input.Search
                onChange={e => {
                  if (e.target.value === '') {
                    setdata(initialData)
                  }
                }}
                allowClear
                enterButton
                placeholder={'Filter by BaseCall Point'}
                onSearch={nameSearch =>
                  setdata(
                    initialData.filter(person =>
                      person.basecCallPoints
                        .map((subItem: any) => subItem.name)
                        .includes(nameSearch)
                    )
                  )
                }
              />
            </AutoComplete>
          ) : null} */}
          <Button onClick={() => setdata(initialData)}>Clear Filter</Button>
        </Space>
      </Row>

      <Tabs defaultActiveKey='1' onChange={onTabChange} className={styles.tabStyle} type='card'>
        <TabPane tab='Active Projects' key='1'>
          <Table
            columns={tableCols}
            dataSource={validData.reverse()}
            rowKey='projectid'
            loading={loading}
            pagination={{
              position: ['bottomCenter'],
              showTotal: total => `${total}` + t(' items'),
              defaultPageSize: 10,
              showSizeChanger: true,
            }}
            scroll={{ x: 'max-content', y: 500 }}
          />
        </TabPane>
        <TabPane tab='Archive Projects' key='2'>
          <Table
            columns={tableColsArchive}
            dataSource={validData2}
            rowKey='projectid'
            loading={archiveloading}
            pagination={{
              position: ['bottomCenter'],
              showTotal: total => `${total}` + t(' items'),
              defaultPageSize: 10,
              showSizeChanger: true,
            }}
            scroll={{ x: 'max-content', y: 500 }}
          />
        </TabPane>
      </Tabs>
    </>
  )
}

export default ProjectTable
