import React, { useState, useEffect, Dispatch } from 'react'
import { Helmet } from 'react-helmet'
import { Layout, Menu, Row, Button, Spin, Badge, notification, Card, Typography } from 'antd'
import { LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../assets/logo-no-text.png'
import PrivateHeader from '../PrivateHeader/PrivateHeader'
import PublicHeader from '../PublicHeader/PublicHeader'
import DefaultFooter from '../Footer/DefaultFooter'
import EmailSupport from '../../components/TechSupport/EmailSupport'
import BackendService from '../../services/backend'
import { setIsLiteDash, setProjectPlanogramData, setTotalCountData } from '../../store/action/index'
import { Params, RootState, ProjectDetails, ProjType } from '../../@types'
import styles from './ProjectPlanogramLayout.module.scss'
import Col from 'antd/es/grid/col'
import { DB_Service } from '../../services/db.service'
import GetDBdata from '../../data/getDBdata'
import { useSocket } from '../../hooks'
const { Title } = Typography

const { Sider, Content, Footer } = Layout
const { SubMenu } = Menu

const ProjectPlanogramLayout: React.FC = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { projectID, ownerID } = useParams<Params>()
  const [fetchLoading, setfetchLoading] = useState<boolean>(true)
  const [callDexie, setCallDexie] = useState(false)
  const projectPlanogramData = useSelector((state: RootState) => state.projectPlanogram)
  const totalCount = useSelector((state: RootState) => state.totalCount)
  const cognitoUser = useSelector((state: RootState) => state.auth.cognitoUser)
  // const selectMenu = history.location.pathname.split('/').slice(3).join('/')
  const basePath = useLocation().pathname.split('/').slice(0, 3).join('/')
  const fullPath = useLocation().pathname
  const selectmenu = history.location.pathname.split('/')[3]
  const { lastJsonMessage, connectionStatusMessage, getWebSocket } = useSocket()
  const email = cognitoUser?.['attributes']?.email

  const company = email?.split('@')[1].includes('bostonbeer')
  const db_service = new DB_Service()
  useEffect(() => {
    if (connectionStatusMessage == 'Open' && lastJsonMessage !== null) {
      if (lastJsonMessage.type === 'PSA' && lastJsonMessage.status == 'complete') {
        setCallDexie(true)
        fetchCount()
      }
    }
  }, [connectionStatusMessage, lastJsonMessage])
  const menuOnSelect = (key: React.ReactText) => {
    history.push({
      pathname: `${basePath}/${key}`,
      state: { buildingID: fullPath.split('/').slice(4)[0] },
    })
  }
  const { Header } = Layout
  const projectLoadingSpin = (
    <Spin size='large' spinning tip={t('project.loading')} indicator={<LoadingOutlined />}>
      <Card className={styles.loadingSpin} />
    </Spin>
  )
  const fetchCount = async () => {
    await BackendService.getProjectTotals(projectID)
      .then(async res => {
        dispatch(setTotalCountData(res))
        setfetchLoading(false)
      })
      .catch(() => {
        console.log('error in catch')
        //dispatch(releaseProjectData())
        // history.push('/')
      })
  }
  const getStatus = async (updatedts: any) => {
    const status = await db_service.get_status(projectID, 'prd')
    const dateDB = new Date(updatedts)
    const dateDexie = new Date(status[0]?.updatedat)
    if (status.length == 0) {
      setCallDexie(true)
    } else if (dateDB > dateDexie) {
      setCallDexie(true)
    } else {
      setCallDexie(false)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      setfetchLoading(true)
      BackendService.getProjectSingle(projectID)
        .then(async res => {
          dispatch(setProjectPlanogramData(res))
          setfetchLoading(false)
          getStatus(res?.updatedts)
        })
        .catch(() => {
          console.log('error in catch')
          //dispatch(releaseProjectData())
          // history.push('/')
        })
    }
    fetchData()
    fetchCount()
    // console.log(`totalCount`, totalCount)
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='description' content={t('user.logo.welcome')} />
        <title>{`${(projectPlanogramData && projectPlanogramData.projName) || 'Loading'} - ${t(
          'sider.company'
        )}`}</title>
      </Helmet>
      <Layout>
        <EmailSupport />

        <Sider width={250} className={styles.sider}>
          <Row className={styles.title} align='middle' justify='center'>
            <img alt='logo' className={styles.logo} src={logo} />
            <div>
              <h1>{t('sider.company')}</h1>

              <h4>Space Analysis System</h4>
              {callDexie === true && <GetDBdata />}
            </div>
          </Row>

          {!fetchLoading ? (
            <Menu
              // defaultSelectedKeys={['projectsetup']}
              selectedKeys={[selectmenu || 'uploads']}
              theme='dark'
              mode='inline'
              defaultOpenKeys={['sub1', 'sub2', 'sub3', 'sub4']}
              // onClick={e => {
              //   console.log('🚀 ~ file: ProjectPlanogramLayout.tsx ~ line 139 ~ e', e)
              // }}
              onSelect={({ key }) => {
                if (key !== 'dashboard1') {
                  dispatch(setIsLiteDash({ isDash: false, dashboardId: '' }))
                }
                console.log('key', key)
                return key === 'uploads'
                  ? history.push(`/projectplanogram/${projectID}/uploads`)
                  : history.push(`/projectplanogram/${projectID}/${key}`)
              }}
            >
              <SubMenu key='sub1' title='Project Setup'>
                <Menu.Item key='uploads' className={styles.menuItem}>
                  Uploads
                </Menu.Item>
              </SubMenu>
              <SubMenu key='sub2' title='Data Validation'>
                <Menu.Item key='validation'>{`Dashboard`}</Menu.Item>

                <Menu.Item key='planogram'>
                  <Row>
                    <Col span={18}> {`Project Planogram `}</Col>
                    <Col span={6}>
                      <Badge
                        style={{
                          backgroundColor: '#7666F9',
                          borderColor: '#000C17',
                          textAlign: 'center',
                        }}
                        overflowCount={9999}
                        count={totalCount?.totalplanograms}
                      />
                    </Col>
                  </Row>
                </Menu.Item>
                <Menu.Item key='ProjectProducts'>
                  <Row>
                    <Col span={18}> {`Project Products`}</Col>
                    <Col span={6}>
                      <Badge
                        style={{
                          backgroundColor: '#7666F9',
                          borderColor: '#000C17',
                          textAlign: 'center',
                          right: 0,
                        }}
                        overflowCount={9999}
                        count={totalCount?.totalproducts}
                      />
                    </Col>
                  </Row>
                </Menu.Item>
                <Menu.Item key='ProjectStores'>
                  <Row>
                    <Col span={18}> {`Project Stores`}</Col>
                    <Col span={6}>
                      <Badge
                        style={{
                          backgroundColor: '#7666F9',
                          borderColor: '#000C17',
                          textAlign: 'center',
                        }}
                        overflowCount={9999}
                        count={totalCount?.totalstores}
                      />
                    </Col>
                  </Row>
                </Menu.Item>
                <Menu.Item key='submitreport'>{`Publish for Reporting`}</Menu.Item>
              </SubMenu>
              <SubMenu key='sub4' title='Analysis'>
                <Menu.Item key='dashboard1'>Space Analysis</Menu.Item>
                {/* <Menu.Item key='podanalysis'>POD Analysis</Menu.Item> */}
              </SubMenu>
              {/* {window.location.href.split('.')[0].includes('bbc') ? null : (
                <SubMenu key='sub4' title='Analysis'>
                  <Menu.Item key='SpaceAnalysis'>Space Analysis</Menu.Item>
                  {/* <Menu.Item key='StoreReport'>Store Report</Menu.Item> */}
              {/* <Menu.Item key='ComparisonReport'>Comparison Report</Menu.Item> */}
              {/* <Menu.Item key='ExcelReport'>Excel Report</Menu.Item> */}
              {/* <Menu.Item key='PivotTable'>Pivot Table</Menu.Item>
                  <Menu.Item key='PivotTable2'>Pivot Table 2</Menu.Item> 
                </SubMenu>
              )} */}

              <SubMenu key='sub5' title='Download Datasets'>
                <Menu.Item key='download'>Downloads</Menu.Item>
              </SubMenu>
            </Menu>
          ) : (
            <div className={styles.spin}>
              <Spin size='large' />
            </div>
          )}
        </Sider>

        <Layout className={styles.main}>
          {cognitoUser ? <PrivateHeader /> : <PublicHeader />}

          <Content className={styles.content}>
            {fetchLoading ? projectLoadingSpin : props.children}
          </Content>

          <Footer className={styles.footer}>
            <DefaultFooter />
          </Footer>
        </Layout>
      </Layout>
    </>
  )
}

export default ProjectPlanogramLayout
