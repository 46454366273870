import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop'
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'
// import Register from './pages/user/Register/index'
import Verification from './pages/user/Verification/index'
import NewPassword from './pages/user/NewPwd/index'
import Login from './pages/user/Login/index'
import ForgetPassword from './pages/user/ForgetPwd/index'
import ChangePassword from './pages/user/ChangePwd/index'
import EmptyLayout from './layouts/EmptyLayout/EmptyLayout'
import UserLayout from './layouts/UserLayout/UserLayout'
import BasicLayout from './layouts/BasicLayout/BasicLayout'
import MyAccountLayout from './layouts/MyAccount/MyAccount'
import ProjectPlanogramLayout from './layouts/ProjectPlanogramLayout/ProjectPlanogramLayout'
import NotFound404 from './pages/404'
import ProjectTable from './pages/ProjectTable/ProjectTable'
import UploadLite from './components/Upload/UploadSetup'

const ProjectSetup = lazy(() => import('./pages/ProjectSetup'))
const UploadMaster = lazy(() => import('./pages/MasterProduct/UploadSetup'))
const ProjectProducts = lazy(() => import('./pages/ProjectProducts'))
const UploadedRequests = lazy(() => import('./pages/UploadedRequests'))
const RetailerLayout = lazy(() => import('./layouts/RetailerLayout/RetailerLayout'))
const UploadedStores = lazy(() => import('./pages/UploadedStores'))
const ProjectPlanogram = lazy(() => import('./pages/ProjectPlanogram'))
const Demo = lazy(() => import('./pages/Charts/Demo'))
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'))
const ProjectReport1 = lazy(() => import('./pages/ProjectReport1/Report1'))
const ProjectReport2 = lazy(() => import('./pages/ProjectReport2/Report2'))
const ComparisonReport = lazy(() => import('./pages/ComparisonReport/ComparisonReport'))
const PivotTable = lazy(() => import('./pages/PivotTable'))
const PivotTable2 = lazy(() => import('./pages/PivotTable2'))
const ExcelReport = lazy(() => import('./pages/ExcelReport'))
const Dos = lazy(() => import('./pages/ProjectReport3/Report3'))
const ShareOf = lazy(() => import('./pages/ShareOf'))
const Overview = lazy(() => import('./pages/Overview'))
const StoreReport = lazy(() => import('./pages/StoreReport/index'))
const ValidationDashboard1 = lazy(() => import('./pages/ValidationDashboard1'))
const SubmitReport = lazy(() => import('./pages/SubmitReport'))
const BarChart = lazy(() => import('./pages/Charts/ProjectReport/BarChart'))
const DownloadDataset = lazy(() => import('./pages/DownloadDatasets'))
const ProjectReport = lazy(() => import('./pages/ProjectReport'))
const ManageRetailers = lazy(() => import('./pages/ManageRetailers'))
const ManageTimePeriod = lazy(() => import('./pages/ManageTimeperiod'))
const ManageUser = lazy(() => import('./pages/CreateUser'))
const Accessdenied = lazy(() => import('./components/AppAccess'))
// const Dashboard1 = lazy(() => import('./components/Analysis/Dasboard1'))
const PODAnalysis = lazy(() => import('./components/Analysis/PODAnalysis'))
const Dashboard1 = lazy(() => import('./components/Analysis/SpaceAnalysis'))

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<EmptyLayout />}>
        <ScrollToTop />
        <Switch>
          <Route path='/user'>
            <UserLayout>
              <Switch>
                <PublicRoute path='/user/login'>
                  <Login />
                </PublicRoute>
                <PublicRoute path='/user/forget'>
                  <ForgetPassword />
                </PublicRoute>
                <PublicRoute path='/user/verify'>
                  <Verification />
                </PublicRoute>
                <PublicRoute path='/user/newpwd'>
                  <NewPassword />
                </PublicRoute>
              </Switch>
            </UserLayout>
          </Route>
          <PrivateRoute path='/manageproject'>
            <BasicLayout>
              <Switch>
                <PrivateRoute path='/manageproject'>
                  <ProjectTable />
                </PrivateRoute>
              </Switch>
            </BasicLayout>
          </PrivateRoute>
          <PrivateRoute path='/reveallite'>
            <BasicLayout>
              <Switch>
                <PrivateRoute path='/reveallite'>
                  <UploadLite />
                </PrivateRoute>
              </Switch>
            </BasicLayout>
          </PrivateRoute>
          <PrivateRoute path='/masterdata'>
            <BasicLayout>
              <Switch>
                <PrivateRoute path='/masterdata'>
                  <UploadMaster type='main' />
                </PrivateRoute>
              </Switch>
            </BasicLayout>
          </PrivateRoute>
          <PrivateRoute path='/manageretailers'>
            <BasicLayout>
              <Switch>
                <PrivateRoute path='/manageretailers'>
                  <ManageRetailers />
                </PrivateRoute>
              </Switch>
            </BasicLayout>
          </PrivateRoute>
          <PrivateRoute path='/myaccount'>
            <MyAccountLayout>
              <Switch>
                <PrivateRoute path='/myaccount'>
                  <ChangePassword />
                </PrivateRoute>
              </Switch>
            </MyAccountLayout>
          </PrivateRoute>
          <PrivateRoute path='/managetimeperiod'>
            <BasicLayout>
              <Switch>
                <PrivateRoute path='/managetimeperiod'>
                  <ManageTimePeriod />
                </PrivateRoute>
              </Switch>
            </BasicLayout>
          </PrivateRoute>
          <PrivateRoute path='/manageuser'>
            <BasicLayout>
              <Switch>
                <PrivateRoute path='/manageuser'>
                  <ManageUser />
                </PrivateRoute>
              </Switch>
            </BasicLayout>
          </PrivateRoute>
          <PrivateRoute path='/projectreport'>
            <BasicLayout>
              <Switch>
                <PrivateRoute path='/projectreport'>
                  <ProjectReport />
                </PrivateRoute>
              </Switch>
            </BasicLayout>
          </PrivateRoute>
          <PrivateRoute path='/report/:projectID/dashboard'>
            <BasicLayout>
              <Switch>
                <PrivateRoute path='/report/:projectID/dashboard'>
                  <BarChart />
                </PrivateRoute>
              </Switch>
            </BasicLayout>
          </PrivateRoute>
          <PrivateRoute path='/comparison/:projectID/dashboard'>
            <BasicLayout>
              <Switch>
                <PrivateRoute path='/comparison/:projectID/dashboard'>
                  <Demo />
                </PrivateRoute>
              </Switch>
            </BasicLayout>
          </PrivateRoute>
          <PrivateRoute path='/projectplanogram/:projectID'>
            <ProjectPlanogramLayout>
              <Switch>
                <PrivateRoute path='/projectplanogram/:projectID/uploads'>
                  <ProjectSetup />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/validation'>
                  <ValidationDashboard1 />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/submitreport'>
                  <SubmitReport />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/planogram'>
                  <ProjectPlanogram />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/ProjectProducts'>
                  <ProjectProducts />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/UploadedRequests'>
                  <UploadedRequests />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/ProjectStores'>
                  <UploadedStores />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/SpaceToSales'>
                  <ProjectReport2
                    setloading={() => {
                      console.log()
                    }}
                    repId='all'
                    file='default'
                  />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/ComparisonReport'>
                  <ComparisonReport />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/ExcelReport'>
                  <ExcelReport />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/PivotTable'>
                  <PivotTable />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/PivotTable2'>
                  <PivotTable2 />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/DoS'>
                  <Dos
                    setloading={() => {
                      console.log()
                    }}
                    repId='all'
                    file='default'
                  />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/Dashboards'>
                  <StoreReport />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/podanalysis'>
                  <PODAnalysis />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/dashboard1'>
                  <Dashboard1 />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/SpaceAnalysis'>
                  <ShareOf />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/StoreReport'>
                  <StoreReport />
                </PrivateRoute>
                <PrivateRoute path='/projectplanogram/:projectID/download'>
                  <DownloadDataset />
                </PrivateRoute>
              </Switch>
            </ProjectPlanogramLayout>
          </PrivateRoute>
          <PrivateRoute path='/retailer/:projectID'>
            <RetailerLayout>
              <Switch>
                <PrivateRoute path='/retailer/:projectID/uploads'>
                  <UploadMaster type='retailer' />
                </PrivateRoute>
              </Switch>
            </RetailerLayout>
          </PrivateRoute>
          <PrivateRoute path='/' exact>
            <BasicLayout>
              <Dashboard />
            </BasicLayout>
          </PrivateRoute>
          <PublicRoute path='/accessdenied' exact>
            <UserLayout>
              <Accessdenied />
            </UserLayout>
          </PublicRoute>
          <Route path='*' component={NotFound404} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default Router
