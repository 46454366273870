import { Auth } from 'aws-amplify'
import { getLanguage } from '../utils/getLanguage'
import { message } from 'antd'

type SignupConfirmArgs = {
  username: string
  verification: string
}

type SigninArgs = {
  username: string
  password: string
}

type SignupArgs = {
  email: string
  password: string
  lastname: string
  firstname: string
}

type UsernameArgs = {
  username: string
}

type ForgotPasswordArgs = {
  username: string
  code: string
  newPassword: string
}
type Attributes = {
  username: string
  password: string
  lastname: string
  firstname: string
}
export const Signup = async ({ email, password, lastname, firstname }: SignupArgs) => {
  return Auth.signUp({
    username: email,
    password: password,
    attributes: {
      family_name: lastname,
      given_name: firstname,
      locale: getLanguage(),
    },
  })
}

export const ConfirmSignUp = async ({ username, verification }: SignupConfirmArgs) => {
  return Auth.confirmSignUp(username, verification)
}

export const SignIn = async ({ username, password }: SigninArgs) => {
  return Auth.signIn(username, password)
}

export const ResendVerification = async ({ username }: UsernameArgs) => {
  return Auth.resendSignUp(username)
}

export const SendForgotPasswordVerification = async ({ username }: UsernameArgs) => {
  return Auth.forgotPassword(username)
}

export const SubmitForgotPassword = async ({ username, code, newPassword }: ForgotPasswordArgs) => {
  return Auth.forgotPasswordSubmit(username, code, newPassword)
}

// export const completeNewPassword = async (user: any, password: string, attributes: any) => {
//   return Auth.completeNewPassword(user, password, attributes)
// }

// export const completeNewPassword = async (
//   user: any,
//   password: string,
//   family_name: string,
//   given_name: string,
//   attributes: any
// ) => {
//   return Auth.completeNewPassword(user, password, {
//     family_name: family_name,
//     given_name: given_name,
//   })
// }

export const completeNewPassword = async (currentUser: any, password: string, attributes: any) => {
  // const currentUser = await Auth.currentAuthenticatedUser()
  // console.log('current user', currentUser)
  return await Auth.completeNewPassword(currentUser, password, attributes)
}
export const updateUser = async (currentUser: any, attributes: any) => {
  await Auth.updateUserAttributes(currentUser, attributes)
}
export const resetPassword = async (oldPassword: any, newPassword: any) => {
  await Auth.currentAuthenticatedUser()
    .then(currentUser => {
      return Auth.changePassword(currentUser, oldPassword, newPassword)
    })
    .then(data => message.success('Your Password Changed'))
    .catch(err => message.error('Your Old Password is Incorrect'))
}

// export const completeNewPassword = async (
//   email: string,
//   currentPassword: string,
//   newPassword: string
// ): Promise<void> => {
//   try {
//     const { requiredAttributes } = currentUser.challengeParam
//     const result = await Auth.completeNewPassword(currentUser, newPassword, requiredAttributes)
//     return result
//   } catch (err) {
//     console.log('err', err)
//   }
// }
// export const completeNewPassword = async (
//   user: any,
//   password: string,
//   familyname: string,
//   givenname: string
// ) => {
//   return Auth.completeNewPassword({
//     user,
//     password,
//   })
// }

// type NewPasswordDetails = {
//   username: string
//   password: string
// }

// export const completeNewPassword = async ({ username, password }: NewPasswordDetails) => {
//   return Auth.completeNewPassword(username, password, [])
// }

// type Attributes = {
//   username: string
//   password: string
// }
// export class User {
//   name: string
//   username: string
//   password: string
//   newPassword: string
//   email: string
//   phoneNumber: string
// }
// export const setupAdminProfile = (data: User, user: any): Promise<any> => {
//   let username = data.username
//   let password = data.newPassword
//   let phone_number = data.phoneNumber
//   let name = data.name
//   return Auth.completeNewPassword(user, password, {
//     name: name,
//     email: username,
//     phone_number: phone_number,
//   })
// }
