import {
  ProjectPlanogram,
  IProjectPlanogramState,
  SetProjectPlanogramDataAction,
  ProjectPlanogramActionTypes,
} from '../../@types'
import * as actionTypes from '../action/actionTypes'

const initialState: IProjectPlanogramState = null

interface IPlanogramReducer<A> {
  (state: IProjectPlanogramState, action: A): IProjectPlanogramState
}

const setProjectPlanogramData: IPlanogramReducer<SetProjectPlanogramDataAction> = (
  state,
  action
) => {
  return {
    ...state,
    ...action.data,
  } as ProjectPlanogram
}

const reducer = (
  state: IProjectPlanogramState = initialState,
  action: ProjectPlanogramActionTypes
): IProjectPlanogramState => {
  switch (action.type) {
    case actionTypes.SET_PROJECTDATA_PLANOGRAM:
      return setProjectPlanogramData(state, action)
    default:
      return state
  }
}

export default reducer
