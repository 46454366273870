export const SET_COGNITOUSER = 'SET_COGNITOUSER'
export const SET_SIGNOUT = 'SET_SIGNOUT'

export const SET_LOCALE = 'SET_LOCALE'

export const SET_UNIT = 'SET_UNIT'

export const SET_PROJECTDATA = 'SET_PROJECTDATA'
export const UPDATE_PROJECTATTRIBUTES = 'UPDATE_PROJECTATTRIBUTES'
export const RELEASE_PROJECTDATA = 'RELEASE_PROJECTDATA'
export const ADD_BUILDING = 'ADD_BUILDING'
export const EDIT_BUILDING = 'EDIT_BUILDING'
export const DELETE_BUILDING = 'DELETE_BUILDING'
export const SET_BUILDING_REGENREPORT = 'SET_BUILDING_REGENREPORT'
export const ADD_PV_SPEC = 'ADD_PV_SPEC'
export const EDIT_PV_SPEC = 'EDIT_PV_SPEC'
export const DELETE_PV_SPEC = 'DELETE_PV_SPEC'
export const ADD_INVERTER_SPEC = 'ADD_INVERTER_SPEC'
export const EDIT_INVERTER_SPEC = 'EDIT_INVERTER_SPEC'
export const DELETE_INVERTER_SPEC = 'DELETE_INVERTER_SPEC'
export const ADD_COMBIBOX = 'ADD_COMBIBOX'
export const EDIT_COMBIBOX = 'EDIT_COMBIBOX'
export const DELETE_COMBIBOX = 'DELETE_COMBIBOX'
export const ADD_TRANSFORMER = 'ADD_TRANSFORMER'
export const EDIT_TRANSFORMER = 'EDIT_TRANSFORMER'
export const DELETE_TRANSFORMER = 'DELETE_TRANSFORMER'
export const ADD_POWERCABINET = 'ADD_POWERCABINET'
export const EDIT_POWERCABINET = 'EDIT_POWERCABINET'
export const DELETE_POWERCABINET = 'DELETE_POWERCABINET'

export const SET_PV_DATA = 'SET_PV_DATA'
export const SET_OFFICIAL_PV_DATA = 'SET_OFFICIAL_PV_DATA'

export const SET_INVERTER_DATA = 'SET_INVERTER_DATA'
export const SET_OFFICIAL_INVERTER_DATA = 'SET_OFFICIAL_INVERTER_DATA'

export const SET_REPORTDATA = 'SET_REPORTDATA'
export const UPDATE_REPORTATTRIBUTES = 'UPDATE_REPORTATTRIBUTES'
export const RELEASE_REPORTDATA = 'RELEASE_REPORTDATA'
export const DELETE_REPORTDATA = 'DELETE_REPORTDATA'

export const SET_PV_DIST = 'SET_PV_DIST'
export const SET_INVERTER_WIDTH = 'SET_INVERTER_WIDTH'
export const SET_INVERTER_DATA_SLD = 'SET_INVERTER_DATA_SLD'
export const SET_INTERCONNECT_DATA = 'SET_INTERCONNECT_DATA'
export const SET_SIZE = 'SET_SIZE'
export const SET_SERVER_PANEL = 'SET_SERVER_PANEL'
export const SET_METER = 'SET_METER'
export const SET_GRID = 'SET_GRID'
export const SET_WIDTH = 'SET_WIDTH'

export const SET_START_POSTION = 'SET_START_POSTION'
export const SET_DIAGRAM_WIDTH = 'SET_DIAGRAM_WIDTH'
export const SET_INVERTER_ACCESSPORTS = 'SET_INVERTER_ACCESSPORTS'
export const SET_METER_ACCESS = 'SET_METER_ACCESS'
export const SET_METER_ACCESS_ALL_IN = 'SET_METER_ACCESS_ALL_IN'
export const SET_DIAGRAM_HEIGHT = 'SET_DIAGRAM_HEIGHT'
export const SET_PROJECTDATA_PLANOGRAM = 'SET_PROJECTDATA_PLANOGRAM'
export const SET_RETAILER = 'SET_RETAILER'

export const SET_FILEDATA_TEMPLATE = 'SET_FILEDATA_TEMPLATE'
export const SET_TOTALCOUNT = 'SET_TOTALCOUNT'

export const SET_ADMIN_USER = 'SET_ADMIN_USER'

export const SET_FILENAME = 'SET_FILENAME'
export const SET_LITE = 'SET_LITE'
