import * as actionTypes from './actionTypes'
import { ThunkAction } from 'redux-thunk'
import { RootState, ProjectPlanogramActionTypes, ProjectPlanogram } from '../../@types'

type ThunkResult<R> = ThunkAction<R, RootState, undefined, ProjectPlanogramActionTypes>
interface IAction<P, T> {
  (param: P): ThunkResult<T>
}
export const setProjectPlanogramData: IAction<Partial<ProjectPlanogram>, void> =
  data => dispatch => {
    dispatch({
      type: actionTypes.SET_PROJECTDATA_PLANOGRAM,
      data: data,
    })
  }
