import { combineReducers } from 'redux'
import authReducer from './authReducer'
import localReducer from './localeReducer'
import projectReducer from './projectReducer'
import projectPlanogramReducer from './projectPlanogramReducer'
import totalCountReducer from './totalCountReducer'
import retailerReducer from './retailerReducer'
import adminUserReducer from './adminUserReducer'
import fileNameReducer from './fileNameReducer'
import liteReducer from './liteReducer'

export const rootReducer = combineReducers({
  auth: authReducer,
  locale: localReducer,
  project: projectReducer,
  projectPlanogram: projectPlanogramReducer,
  totalCount: totalCountReducer,
  retailer: retailerReducer,
  adminuser: adminUserReducer,
  fileName: fileNameReducer,
  liteDash: liteReducer,
})
