import React, { useState, useCallback } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
// import { AuthContext } from 'services/auth';
import Auth from '@aws-amplify/auth'
// import { connect } from 'react-redux'

const useSocket = () => {
  const getSocketUrl = useCallback(() => {
    return new Promise<string>(resolve => {
      setTimeout(async () => {
        resolve(
          `wss://2vdsa0clve.execute-api.us-east-1.amazonaws.com/production?Authorization=${(
            await Auth.currentSession()
          )
            .getIdToken()
            .getJwtToken()}`
        )
      }, 2000)
    })
  }, [])

  const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket(
    getSocketUrl,
    {
      // share: true,
      // onOpen: () => console.log('opened'),
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: closeEvent => true,
      reconnectAttempts: 3,
      // reconnectInterval: 3000,
    }
  )
  React.useEffect(() => () => getWebSocket()?.close(), [getWebSocket])

  const connectionStatusMessage = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]
  return {
    sendJsonMessage,
    getWebSocket,
    lastJsonMessage,
    connectionStatus: readyState,
    connectionStatusMessage: connectionStatusMessage,
  }
}

export default useSocket
