import { Collection } from 'dexie'
import { RevealDB } from '../data/db'
import { PlanogramProducts, ControlTable, ProjectPlanogram, ProjectStores } from '../data/Models'

//import { ProductStoresTable, ProjectPlanogramTable } from '../@types'
//import { PlanogramProducts, ProjType, HierarchyType } from './ByStore'
// import { PlanogramProducts } from './lift'
export class DB_Service {
  db: RevealDB

  constructor() {
    this.db = new RevealDB()
  }
  getAllProducts(projId: string): Collection<PlanogramProducts, number> {
    return this.db.projectproducts.where('projectid').equals(`${projId}`)
  }
  async get_all_prod(projId: string): Promise<PlanogramProducts[]> {
    return await this.getAllProducts(projId).toArray()
  }

  getAllPlanogram(projId: string): Collection<ProjectPlanogram, number> {
    return this.db.projectplanogram.where('projectid').equals(`${projId}`)
  }
  async get_all_plan(projId: string): Promise<ProjectPlanogram[]> {
    return await this.getAllPlanogram(projId).toArray()
  }

  getAllStores(projId: string): Collection<ProjectStores, number> {
    return this.db.projectstores.where('projectid').equals(`${projId}`)
  }
  async get_all_stores(projId: string): Promise<ProjectStores[]> {
    return await this.getAllStores(projId).toArray()
  }
  getStatus(projId: string, object: string): Collection<ControlTable, number> {
    return this.db.controlltable.where('[projectid+object]').equals([projId, object])
  }
  async get_status(projId: string, object: string): Promise<ControlTable[]> {
    return await this.getStatus(projId, object).toArray()
  }
}
