import { FileName, IFileNameState, SetFileNameDataAction, FileNameActionTypes } from '../../@types'
import * as actionTypes from '../action/actionTypes'

const initialState: IFileNameState = null

interface IFileNameReducer<A> {
  (state: IFileNameState, action: A): IFileNameState
}

const setFileNameData: IFileNameReducer<SetFileNameDataAction> = (state, action) => {
  return {
    ...state,
    ...action.data,
  } as FileName
}

const reducer = (
  state: IFileNameState = initialState,
  action: FileNameActionTypes
): IFileNameState => {
  switch (action.type) {
    case actionTypes.SET_FILENAME:
      return setFileNameData(state, action)
    default:
      return state
  }
}

export default reducer
