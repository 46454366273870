import React, { useState, useEffect } from 'react'
import {
  LogoutOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  EditOutlined,
  BellFilled,
  BellOutlined,
} from '@ant-design/icons'
import { Layout, Avatar, Badge, Dropdown, Menu, Row, Button, Tag, Space, Card, Popover } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import styles from './PrivateHeader.module.scss'
import _ from 'lodash'
import { Auth } from 'aws-amplify'
import { genInitial } from '../../utils/genInitial'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setSignOut, setAdminUserData, setIsLiteDash } from '../../store/action/index'
import { RootState } from '../../@types'
// import EditModal from '../../../src/pages/ProjectPlanogram/elements/EditModal'
import ModalWithFormExample from './Modal'
import BackendService from '../../services/backend'
import { Params } from '../../@types'
import { useParams } from 'react-router-dom'
import { setProjectPlanogramData, setTotalCountData } from '../../store/action/index'
import { List, Typography, Divider } from 'antd'
import dayjs from 'dayjs'
// import moment from 'moment'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

import { db } from '../../data/db'
const { Header } = Layout

const PrivateHeader: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const [loading, setloading] = useState(false)
  const [noOfNotification, setNoOfNotification] = useState<number>(0)
  const [listNotification, setListNotification] = useState([])
  const [lastreadtime, setLastReadtime] = useState<number>(0)
  const [usr, setUsr] = useState<any>()

  const projectExist = useLocation().pathname.split('/')[1] === 'project'
  const currentProjectExist = useLocation().pathname.split('/')[1] === 'projectplanogram'
  const myProfile = useLocation().pathname.split('/')[1] === 'myaccount'
  const cognitoUser = useSelector((state: RootState) => state.auth.cognitoUser)
  const currentProject = useSelector((state: RootState) => state.projectPlanogram)
  const isDash = useSelector((state: RootState) => state.liteDash)
  const currentTab = useLocation().pathname.includes('reveallite')

  useEffect(() => {
    setUsr(cognitoUser?.attributes?.given_name)
  }, [cognitoUser])

  const getNotifications = () => {
    setloading(true)
    BackendService.getNotifications().then(data => {
      const msgsunsorted = data[0]?.notifications
      const msgs: any = _.sortBy(msgsunsorted, 'tm').reverse()
      const lastupdated = Date.parse(data[0]?.lastreadtime)
      setLastReadtime(lastupdated)
      const newlist = msgs.filter((item: any) => Date.parse(item.tm) > lastupdated)
      setNoOfNotification(newlist.length)
      setListNotification(msgs)
      setloading(false)
    })
  }
  const getNotificationClick = () => {
    setloading(true)
    BackendService.getNotifications().then(data => {
      const msgsunsorted = data[0]?.notifications
      const msgs: any = _.sortBy(msgsunsorted, 'tm').reverse()
      const lastupdated = Date.parse(data[0]?.lastreadtime)
      setLastReadtime(lastupdated)
      // const newlist = msgs.filter((item: any) => Date.parse(item.tm) > lastupdated)
      // setNoOfNotification(newlist.length)
      setListNotification(msgs)
      setloading(false)
    })
  }
  const updateNotifications = () => {
    setloading(true)
    BackendService.updateNotifications().then(data => {
      setloading(false)
    })
  }
  // console.log('currentProject', currentProject)
  useEffect(() => {
    getNotifications()
  }, [])
  const { projectID, ownerID } = useParams<Params>()
  const signOut = () => {
    dispatch(setSignOut())
    dispatch(setAdminUserData({ id: undefined }))
    Auth.signOut()
    history.push('/user/login')
    db.delete()
      .then(() => {
        // console.log('Database successfully deleted')
      })
      .catch(err => {
        // console.error('Could not delete database')
      })
      .finally(() => {
        // Do what should be done next...
      })
  }
  const myAccount = () => {
    history.push('/myaccount')
    // dispatch(setSignOut())
    // dispatch(setAdminUserData({ id: undefined }))
    // // Auth.signOut()
    // db.delete()
    //   .then(() => {
    //     // console.log('Database successfully deleted')
    //   })
    //   .catch(err => {
    //     // console.error('Could not delete database')
    //   })
    //   .finally(() => {
    //     // Do what should be done next...
    //   })
  }
  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]}>
      <Menu.Item key='myAccount' onClick={myAccount}>
        <UserOutlined />
        {' My Account'}
      </Menu.Item>
      <Menu.Item key='logout' onClick={signOut}>
        <LogoutOutlined />
        {' Logout'}
      </Menu.Item>
    </Menu>
  )

  const listOnClick = (items: any, i: any) => {
    // const arr = initiallistNotification.filter(function (item) {
    //   return item !== items
    // })
    // setInitialListNotification(arr)

    // setListNotification(arr)
    console.log('items :>> ', items)
  }
  const currenttime: any = new Date()
  const notificationLIst = (
    <List
      dataSource={listNotification}
      style={{ width: '350px' }}
      renderItem={(item: any, i) =>
        Date.parse(item.tm) - lastreadtime > 0 ? (
          <List.Item
            onClick={() => listOnClick(item, i)}
            className={styles.listitemlatest}
            extra={<div>{dayjs(item.tm).fromNow()}</div>}
          >
            {item.msg}
          </List.Item>
        ) : (
          <List.Item
            onClick={() => listOnClick(item, i)}
            className={styles.listitem}
            extra={
              <div>
                {dayjs(item.tm).fromNow()}
                {/* {moment(item.tm).fromNow()} */}
              </div>
            }
          >
            {item.msg}
          </List.Item>
        )
      }
    />
  )

  return (
    <Header className={styles.header}>
      <div className={styles.left}>
        {myProfile ? (
          <>
            <Space>
              <Button
                block
                size='large'
                // className={styles.saveBut}
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  history.push('/')
                }}
              >
                {'Back to Dashboard'}
              </Button>
            </Space>
          </>
        ) : null}
        {isDash?.isDash && currentTab && (
          <Button
            block
            // type='outlined'
            size='large'
            className={styles.liteBut}
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              dispatch(
                setIsLiteDash({
                  isDash: false,
                })
              )

              history.push('/reveallite')
            }}
          >
            {'Back'}
          </Button>
        )}
        {currentProjectExist && currentProject ? (
          <>
            <Space>
              {/* {isDash?.isDash ? (
                <Button
                  block
                  // type='outlined'
                  size='large'
                  className={styles.liteBut}
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    dispatch(
                      setIsLiteDash({
                        isDash: false,
                      })
                    )

                    history.push(`/projectplanogram/${projectID}/dashboard1`)
                  }}
                >
                  {'Back'}
                </Button>
              ) : ( */}
              <Button
                block
                // type='outlined'
                size='large'
                className={styles.saveBut}
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  dispatch(
                    setProjectPlanogramData({
                      projectid: '',
                      projName: '',
                      projHierarchy: '',
                      createdAt: '',
                      basecCallPoints: [],
                      multiBase: undefined,
                    })
                  )
                  dispatch(
                    setTotalCountData({
                      totalstores: 0,
                      totalplanograms: 0,
                      totalproducts: 0,
                      totalmanufacturers: 0,
                    })
                  )
                  dispatch(setIsLiteDash({ isDash: false, dashboardId: '' }))

                  history.push('/manageproject')
                }}
              >
                {'Exit Project'}
              </Button>
              {/* )} */}

              {currentProject?.projName !== ''
                ? `${currentProject?.projName} (${currentProject?.retailerName})`
                : null}
              <ModalWithFormExample />
              {currentProject?.multiBase ? (
                <Tag>Multi Base Project</Tag>
              ) : currentProject?.basecCallPoints ? (
                currentProject?.basecCallPoints.map((base: any, i: any) => (
                  <>
                    <Tag key={i}>{`${base.name} - ${base.id}`}</Tag>
                  </>
                ))
              ) : null}
            </Space>
          </>
        ) : null}
      </div>

      <Row className={styles.right} align='middle'>
        <Popover
          placement='bottomRight'
          title={<div style={{ marginTop: '5px', marginBottom: '5px' }}>Notifications</div>}
          content={notificationLIst}
          onVisibleChange={e => (!e ? getNotificationClick() : null)}
          trigger='click'
        >
          <Badge style={{ backgroundColor: '#52c41a' }} count={noOfNotification}>
            <Button
              type='text'
              shape='circle'
              icon={
                <BellFilled
                  style={{ fontSize: '26px' }}
                  onClick={e => {
                    updateNotifications()
                    setNoOfNotification(0)
                  }}
                />
              }
            />
          </Badge>
        </Popover>
        <Dropdown overlay={menuHeaderDropdown}>
          <div className={styles.item}>
            <Avatar className={styles.avatar} alt='avatar' icon={<UserOutlined />} />
            {cognitoUser && usr}
          </div>
        </Dropdown>
      </Row>
    </Header>
  )
}

export default PrivateHeader
