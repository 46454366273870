import * as actionTypes from './actionTypes'
import { ThunkAction } from 'redux-thunk'
import { RootState, TotalCount, TotalCountActionTypes } from '../../@types'

type ThunkResult<R> = ThunkAction<R, RootState, undefined, TotalCountActionTypes>
interface IAction<P, T> {
  (param: P): ThunkResult<T>
}
export const setTotalCountData: IAction<Partial<TotalCount>, void> = data => dispatch => {
  dispatch({
    type: actionTypes.SET_TOTALCOUNT,
    data: data,
  })
}
