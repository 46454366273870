/* eslint-disable react/react-in-jsx-scope */

import { Form, Input, Button, Checkbox, Modal, Cascader, Radio, Select } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { RootState } from '../../@types'

import React from 'react'
import { useState } from 'react'
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}
const { Option } = Select

export default function ModalWithFormExample() {
  const currentProject = useSelector((state: RootState) => state.projectPlanogram)
  // const total = currentProject?.ri?.split('#')
  // const currentProject?.storeExtraction = total ? Number(total[1]) : null
  // const productkey = total ? total[2] : null
  // const retail = total ? total[3] : null
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (values: any) => {
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
  }
  const heirarchy = [
    {
      label: 'North',
      value: 'North',
      children: [
        {
          value: 'North / Heirarchy 1',
          label: 'Heirarchy 1',
        },
        {
          value: 'North / Heirarchy 2',
          label: 'Heirarchy 2',
        },
        {
          value: 'North / Heirarchy 3',
          label: 'Heirarchy 3',
        },
      ],
    },
    {
      label: 'South',
      value: 'South',
      children: [
        {
          value: 'South / Heirarchy 4',
          label: 'Heirarchy 4',
        },
        {
          value: 'South / Heirarchy 5',
          label: 'Heirarchy 5',
        },
        {
          value: 'South / Heirarchy 6',
          label: 'Heirarchy 6',
        },
      ],
    },
    {
      label: 'East',
      value: 'East',
      children: [
        {
          value: 'East / Heirarchy 7',
          label: 'Heirarchy 7',
        },
        {
          value: 'East / Heirarchy 8',
          label: 'Heirarchy 8',
        },
        {
          value: 'East / Heirarchy 9',
          label: 'Heirarchy 9',
        },
      ],
    },
    {
      label: 'West',
      value: 'West',
      children: [
        {
          value: 'West / Heirarchy 10',
          label: 'Heirarchy 10',
        },
        {
          value: 'West / Heirarchy 11',
          label: 'Heirarchy 11',
        },
        {
          value: 'West / Heirarchy 12',
          label: 'Heirarchy 12',
        },
      ],
    },
  ]

  const storeExtraction = [
    {
      value: 'From File Name',
      label: 'From File Name',
      children: [
        {
          value: 'From File Name / Use PSA File Name',
          label: 'Use PSA File Name',
        },
        {
          value: 'Use PSA File Name / Use Planogram Project Name',
          label: 'Use Planogram Project Name',
        },
        {
          value: 'Use PSA File Name / Use Planogram Name',
          label: 'Use Planogram Name',
        },
      ],
    },
    {
      value: 'Use Planogram Description Field',
      label: 'Use Planogram Description Field',
      children: [
        {
          value: 'Use Planogram Description Field / Desc 1',
          label: 'Desc 1',
        },
        {
          value: 'Use Planogram Description Field / Desc 2',
          label: 'Desc 2',
        },
        {
          value: 'Use Planogram Description Field / Desc 3',
          label: 'Desc 3',
        },
        {
          value: 'Use Planogram Description Field / Desc 4',
          label: 'Desc 4',
        },
        {
          value: 'Use Planogram Description Field / Desc 5',
          label: 'Desc 5',
        },
        {
          value: 'Use Planogram Description Field / Desc 6',
          label: 'Desc 6',
        },
        {
          value: 'Use Planogram Description Field / Desc 7',
          label: 'Desc 7',
        },
        {
          value: 'Use Planogram Description Field / Desc 8',
          label: 'Desc 8',
        },
        {
          value: 'Use Planogram Description Field / Desc 9',
          label: 'Desc 9',
        },
        {
          value: 'Use Planogram Description Field / Desc 10',
          label: 'Desc 10',
        },
      ],
    },
  ]

  // console.log('currentProject', currentProject)
  return (
    <>
      <EditOutlined style={{ marginLeft: '10px' }} onClick={showModal} />
      <Modal
        title='Project Details'
        visible={visible}
        onOk={form.submit}
        onCancel={handleCancel}
        width={'50vw'}
      >
        <Form form={form} onFinish={handleSubmit} {...layout}>
          <Form.Item
            label='Project Name'
            name='projName'
            rules={[{ required: true, message: 'Please input your project name' }]}
          >
            <Input placeholder={currentProject?.projName} readOnly />
          </Form.Item>
          <Form.Item
            label='Retailer Name'
            name='retailName'
            rules={[{ required: true, message: 'Please Input your Retailer name' }]}
          >
            <Input
              placeholder={currentProject?.retailerName ? currentProject?.retailerName : ''}
              readOnly
            />
          </Form.Item>
          {/* <Form.Item
            label='Project Hierarchy'
            name='projHierarchy'
            rules={[{ required: true, message: 'Please input your project hierarchy' }]}
          >
            <Cascader options={heirarchy} placeholder={currentProject?.projHierarchy} />
          </Form.Item> */}
          <Form.Item
            label='Store Extraction'
            name='strExts'
            rules={[
              {
                type: 'array',
                required: true,
                message: 'Please select your Store Extraction',
              },
            ]}
          >
            {currentProject?.storeExtraction == 1 && (
              <Input defaultValue='From File Name / Use PSA File Name' readOnly />
            )}
            {currentProject?.storeExtraction == 2 && (
              <Input defaultValue='Use PSA File Name / Use Planogram Project Name' readOnly />
            )}
            {currentProject?.storeExtraction == 3 && (
              <Input defaultValue='Use PSA File Name / Use Planogram Name' readOnly />
            )}
            {currentProject?.storeExtraction == 4 && (
              <Input defaultValue='Use Planogram Description Field / Desc 1' readOnly />
            )}
            {currentProject?.storeExtraction == 5 && (
              <Input defaultValue='Use Planogram Description Field / Desc 2' readOnly />
            )}
            {currentProject?.storeExtraction == 6 && (
              <Input defaultValue='Use Planogram Description Field / Desc 3' readOnly />
            )}
            {currentProject?.storeExtraction == 7 && (
              <Input defaultValue='Use Planogram Description Field / Desc 4' readOnly />
            )}
            {currentProject?.storeExtraction == 8 && (
              <Input defaultValue='Use Planogram Description Field / Desc 5' readOnly />
            )}
            {currentProject?.storeExtraction == 9 && (
              <Input defaultValue='Use Planogram Description Field / Desc 6' readOnly />
            )}
            {currentProject?.storeExtraction == 10 && (
              <Input defaultValue='Use Planogram Description Field / Desc 7' readOnly />
            )}
            {currentProject?.storeExtraction == 11 && (
              <Input defaultValue='Use Planogram Description Field / Desc 8' readOnly />
            )}
            {currentProject?.storeExtraction == 12 && (
              <Input defaultValue='Use Planogram Description Field / Desc 9' readOnly />
            )}
            {currentProject?.storeExtraction == 13 && (
              <Input defaultValue='Use Planogram Description Field / Desc 10' readOnly />
            )}
          </Form.Item>

          <Form.Item
            label='Product Key'
            name='productKey'
            rules={[{ required: true, message: 'Please select a product key' }]}
          >
            {currentProject?.productKey ? (
              <Input defaultValue={currentProject?.productKey} readOnly />
            ) : null}
          </Form.Item>
          <Form.Item
            label='Reset Time'
            name='projTime'
            rules={[{ required: true, message: 'Please select a Reset time' }]}
          >
            <Select placeholder={currentProject?.projectTime} allowClear>
              <Option value='Q12020'>Q1 2020</Option>
              <Option value='Q22020'>Q2 2020</Option>
              <Option value='Q32020'>Q3 2020</Option>
              <Option value='Q42020'>Q4 2020</Option>
              <Option value='Q12021'>Q1 2021</Option>
              <Option value='Q22021'>Q2 2021</Option>
              <Option value='Q32021'>Q3 2021</Option>
              <Option value='Q42021'>Q4 2021</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
