import React, { useEffect, useCallback } from 'react'
import { useWorker } from '@koale/useworker'
import Auth from '@aws-amplify/auth'
import { useParams } from 'react-router-dom'
import { Params } from '../@types'
const getData = (projid: string, token: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Dexie = Dexie.default
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const db = new Dexie('RevealDB')
  db.version(1).stores({
    projectproducts:
      '[projectid+UPC],Category,ProductManufacturer,SubCategory,Package,Segment,Subsegment',
    projectstores: '[projectid+storenumber]',
    projectplanogram: '[projectid+pogkey]',
    controlltable: '[projectid+object]',
    productstore: '[projectid+UPC]',
    hierarchy: '[projectid+object+objectkey+objectvalue]',
    // temptable: '[projectid+masterupc+storenumber]',
  })
  const hdata = [
    { projectid: projid, object: 'planogram', objectvalue: '2 ft', objectkey: '2 ft', count: '1' },
    { projectid: projid, object: 'planogram', objectvalue: '4 ft', objectkey: '4 ft', count: '46' },
  ]

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // axios
  //   .get('https://revealdocs.s3.amazonaws.com/bbc/reports/current/__vZQ4gawM/report.zip', {
  //     responseType: 'arraybuffer',
  //     // responseEncoding: null,
  //   })
  //   .then((zip: any) => {
  //     console.log('before UnZip', new Date().toISOString())
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     JSZip.loadAsync(zip.data, { base64: true }).then((res: any) => {
  //       Object.keys(res.files).forEach(function (filename) {
  //         res.files[filename].async('string').then(function (fileData: any) {
  //           console.log('after UnZip', new Date().toISOString())
  //           try {
  //             console.log('before inserting into table', new Date().toISOString())
  //             db.table('temptable').bulkAdd(JSON.parse(fileData))
  //             console.log('after insert into table', new Date().toISOString())
  //           } catch (err) {
  //             console.log(err)
  //           }
  //         })
  //       })
  //     })
  //   })
  // db.table('hierarchy')
  //   .where({ projectid: projid })
  //   .delete()
  //   .then((res: any) => {
  //     db.table('hierarchy')
  //       .bulkAdd(hdata)
  //       .then((res: any) => {
  //         // console.log('inserted count in hierarchy', res)
  //         // db.table('controlltable').put({
  //         //   projectid: projid,
  //         //   object: 'prd',
  //         //   updatedat: new Date().toISOString(),
  //         //   status: 'complete',
  //         // })
  //       })
  //       .catch((err: any) => {
  //         console.log('err in hierarchy', err)
  //       })
  //   })

  db.table('controlltable').put({
    projectid: projid,
    object: 'prd',
    updatedat: new Date().toISOString(),
    status: 'in-progress',
  })
  // console.log('🚀start time', new Date().toISOString())
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  axios
    .post(
      `https://j88z3cghz3.execute-api.us-east-1.amazonaws.com/projectproducts/${projid}`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    .then((data: any) => {
      // console.log('🚀 ~ file: worker.js ~ line 32 ~ zip', data.data.data)

      db.table('projectproducts')
        .where({ projectid: projid })
        .delete()
        .then((res: any) => {
          // console.log('deleted prod data count', res)
          db.table('projectproducts')
            .bulkAdd(data.data.data)
            .then((res: any) => {
              // console.log('inserted count in prd', res)
              db.table('controlltable').put({
                projectid: projid,
                object: 'prd',
                updatedat: new Date().toISOString(),
                status: 'complete',
              })
            })
            .catch((err: any) => {
              // console.log('err in prd', err)
            })
        })
    })
  db.table('controlltable').put({
    projectid: projid,
    object: 'str',
    updatedat: new Date().toISOString(),
    status: 'in-progress',
  })
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  axios
    .get(`https://j88z3cghz3.execute-api.us-east-1.amazonaws.com/projectstores/${projid}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((data: any) => {
      // console.log('store data', data)
      db.table('projectstores')
        .where({ projectid: projid })
        .delete()
        .then((res: any) => {
          db.table('projectstores')
            .bulkAdd(data.data.data)
            .then((res: any) => {
              // console.log('inserted count in str', res)
              db.table('controlltable').put({
                projectid: projid,
                object: 'str',
                updatedat: new Date().toISOString(),
                status: 'complete',
              })
            })
            .catch((err: any) => {
              // console.log('err in str', err)
            })
        })
    })
  db.table('controlltable').put({
    projectid: projid,
    object: 'pln',
    updatedat: new Date().toISOString(),
    status: 'in-progress',
  })
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  axios
    .get(` https://j88z3cghz3.execute-api.us-east-1.amazonaws.com/projectplanogramsv1/${projid}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((data: any) => {
      // console.log('planogram data', data)
      db.table('projectplanogram')
        .where({ projectid: projid })
        .delete()
        .then((res: any) => {
          db.table('projectplanogram')
            .bulkAdd(data.data.data)
            .then((res: any) => {
              // console.log('inserted count in pln', res)
              db.table('controlltable').put({
                projectid: projid,
                object: 'pln',
                updatedat: new Date().toISOString(),
                status: 'complete',
              })
            })
            .catch((err: any) => {
              // console.log('err in str', err)
            })
        })
    })
  db.table('controlltable').put({
    projectid: projid,
    object: 'prs',
    updatedat: new Date().toISOString(),
    status: 'in-progress',
  })
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // axios
  //   .get(` https://j88z3cghz3.execute-api.us-east-1.amazonaws.com/projectplanogramsv1/${projid}`, {
  //     headers: {
  //       authorization: `Bearer ${token}`,
  //     },
  //   })
  //   .then((data: any) => {
  const data = [
    {
      projectid: projid,
      UPC: 21513515325,
      name: 'product',
      '201_sum_facings': 10,
      '201_sum_cubic': 1113,

      '203_sum_facings': 1110,
      '203_sum_cubic': 13,

      '204_sum_facings': 1103,
      '204_sum_cubic': 134,

      '2304_sum_facings': 183,
      '2304_sum_cubic': 1233,
    },
    {
      projectid: projid,
      UPC: 2365625600,
      name: 'product2',
      '201_sum_facings': 170,
      '201_sum_cubic': 183,

      '203_sum_facings': 1109,
      '203_sum_cubic': 143,

      '204_sum_facings': 1303,
      '204_sum_cubic': 1134,

      '2304_sum_facings': 1583,
      '2304_sum_cubic': 1233,
    },
    {
      projectid: projid,
      UPC: 21513515324,
      name: 'product',
      '201_sum_facings': 10,
      '201_sum_cubic': 1113,

      '203_sum_facings': 1110,
      '203_sum_cubic': 13,

      '204_sum_facings': 1103,
      '204_sum_cubic': 134,

      '2304_sum_facings': 183,
      '2304_sum_cubic': 1233,
    },
    {
      projectid: projid,
      UPC: 23656256004,
      name: 'product2',
      '201_sum_facings': 170,
      '201_sum_cubic': 183,

      '203_sum_facings': 1109,
      '203_sum_cubic': 143,

      '204_sum_facings': 1303,
      '204_sum_cubic': 1134,

      '2304_sum_facings': 1583,
      '2304_sum_cubic': 1233,
    },
    {
      projectid: projid,
      UPC: 21513515329,
      name: 'product',
      '201_sum_facings': 10,
      '201_sum_cubic': 1113,

      '203_sum_facings': 1110,
      '203_sum_cubic': 13,

      '204_sum_facings': 1103,
      '204_sum_cubic': 134,

      '2304_sum_facings': 183,
      '2304_sum_cubic': 1233,
    },
    {
      projectid: projid,
      UPC: 23656256009,
      name: 'product2',
      '201_sum_facings': 170,
      '201_sum_cubic': 183,

      '203_sum_facings': 1109,
      '203_sum_cubic': 143,

      '204_sum_facings': 1303,
      '204_sum_cubic': 1134,

      '2304_sum_facings': 1583,
      '2304_sum_cubic': 1233,
    },
    {
      projectid: projid,
      UPC: 21513515328,
      name: 'product',
      '201_sum_facings': 10,
      '201_sum_cubic': 1113,

      '203_sum_facings': 1110,
      '203_sum_cubic': 13,

      '204_sum_facings': 1103,
      '204_sum_cubic': 134,

      '2304_sum_facings': 183,
      '2304_sum_cubic': 1233,
    },
    {
      projectid: projid,
      UPC: 23656256008,
      name: 'product2',
      '201_sum_facings': 170,
      '201_sum_cubic': 183,

      '203_sum_facings': 1109,
      '203_sum_cubic': 143,

      '204_sum_facings': 1303,
      '204_sum_cubic': 1134,

      '2304_sum_facings': 1583,
      '2304_sum_cubic': 1233,
    },
    {
      projectid: projid,
      UPC: 21513515327,
      name: 'product',
      '201_sum_facings': 10,
      '201_sum_cubic': 1113,

      '203_sum_facings': 1110,
      '203_sum_cubic': 13,

      '204_sum_facings': 1103,
      '204_sum_cubic': 134,

      '2304_sum_facings': 183,
      '2304_sum_cubic': 1233,
    },
    {
      projectid: projid,
      UPC: 23656256007,
      name: 'product2',
      '201_sum_facings': 170,
      '201_sum_cubic': 183,

      '203_sum_facings': 1109,
      '203_sum_cubic': 143,

      '204_sum_facings': 1303,
      '204_sum_cubic': 1134,

      '2304_sum_facings': 1583,
      '2304_sum_cubic': 1233,
    },
    {
      projectid: projid,
      UPC: 21513515326,
      name: 'product',
      '201_sum_facings': 10,
      '201_sum_cubic': 1113,

      '203_sum_facings': 1110,
      '203_sum_cubic': 13,

      '204_sum_facings': 1103,
      '204_sum_cubic': 134,

      '2304_sum_facings': 183,
      '2304_sum_cubic': 1233,
    },
    {
      projectid: projid,
      UPC: 23656256006,
      name: 'product2',
      '201_sum_facings': 170,
      '201_sum_cubic': 183,

      '203_sum_facings': 1109,
      '203_sum_cubic': 143,

      '204_sum_facings': 1303,
      '204_sum_cubic': 1134,

      '2304_sum_facings': 1583,
      '2304_sum_cubic': 1233,
    },
  ]

  //   console.log('prs data', data)
  //   db.table('productstore')
  //     .where({ projectid: projid })
  //     .delete()
  //     .then((res: any) => {
  //       db.table('productstore')
  //         .bulkAdd(data)
  //         .then((res: any) => {
  //           console.log('inserted count in prs', res)
  //           db.table('controlltable').put({
  //             projectid: projid,
  //             object: 'prs',
  //             updatedat: new Date().toISOString(),
  //             status: 'complete',
  //           })
  //         })
  //         .catch((err: any) => {
  //           console.log('err in prs', err)
  //         })
  //     })
}

const WebWorkerFn = () => {
  const [getDataWorker, { status: workerStatus, kill: workerTerminate }] = useWorker(getData, {
    // timeout: 50000,
    autoTerminate: false,
    remoteDependencies: [
      'https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/jszip/3.7.1/jszip.js',
      'https://cdnjs.cloudflare.com/ajax/libs/dexie/3.2.0-beta.3/dexie.min.js',
    ],
  })
  const paramData = useParams<Params>()
  const projId = paramData.projectID
  const handleData = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const result = await getDataWorker(projId, token)
  }

  useEffect(() => {
    handleData()
  }, [])
  return (
    <>
      <div>{/* <Button onClick={handleData}>Run </Button> */}</div>
    </>
  )
}

export default WebWorkerFn
