import React, { useState, useEffect, useRef } from 'react'
import { Steps, Skeleton, Badge, Result, Tooltip, Dropdown, Menu, Input } from 'antd'
import { UploadOutlined, LoadingOutlined, CheckCircleTwoTone } from '@ant-design/icons'
import { Storage } from 'aws-amplify'
import styles from './uploadXl.module.scss'
import { Params, TableLite } from '../../@types'
import { CheckOutlined } from '@ant-design/icons'
import { FileList, UploadRequest } from '../../@types'
import { ColumnsType } from 'antd/lib/table'
import prettyBytes from 'pretty-bytes'
import { useSelector, useDispatch } from 'react-redux'
import { setTotalCountData } from '../../store/action/index'
import { Switch, Button, Row, Col, Card, Progress } from 'antd'
import { Table, Tag, Space, Modal, Alert, List, Typography, Divider } from 'antd'
import BackendService from '../../services/backend'
import { CompletedFiles, RootState } from '../../@types'
import zipTargetFiles from '../../components/Compress'
import UploadliteTable from '../Table/UploadliteTable'
import Superset from '../../components/Superset'
import UppyDash from './Uppi/Uppy'
import { useUppy } from '@uppy/react'
import Uppy from '@uppy/core'

const { Step } = Steps
function UploadSetup() {
  const [files, setFiles] = useState<any[]>([])
  const [process, setProcess] = useState(false)
  const [step, setstep] = useState(0)
  const [dataReq, setdataReq] = useState<UploadRequest>({
    status: 'processing',
    filecount: 0,
    fileprocessing: 0,
    filecompleted: 0,
    fileerrored: 0,
  })
  const [reqId, setReqId] = useState<string>('')
  const [projId, setProjId] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [uploadType, setUploadType] = useState('PSAL')
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [tableLiteData, setTableLiteData] = useState<TableLite[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const [uploadFileCount, setUploadFileCount] = useState(0)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isAdded, setIsAdded] = useState(false)
  const [filecount, setFilecount] = useState(0)
  const [name, setName] = useState('')
  const isSup = useSelector((state: RootState) => state.liteDash)

  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: false,
      restrictions: {
        // maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['.psa', 'application/psa', '.PSA'],
      },
    }).on('complete', result => {
      setFilecount(result.successful.length)

      const files = result.successful.map(item => item.data)
      setFiles(files)
      setstep(0)
      setIsUploading(true)

      createProj(files)
      setIsModalVisible(true)
      // compressedUpload(files)
    })
  })
  useEffect(() => {
    fetchTableData()
  }, [dispatch])
  const fetchTableData = async () => {
    setLoading(true)
    BackendService.listProjectsLite().then(data => {
      const sorted = data
        .filter((element: any) => element.status === 'processing' || element.status === 'complete')
        .sort((a, b) => new Date(b.createdat).getTime() - new Date(a.createdat).getTime())

      setTableLiteData(sorted)
      setLoading(false)
    })
  }
  const fileCols: ColumnsType<FileList> = [
    {
      title: 'File Name',
      dataIndex: 'name',
      fixed: 'left',
      width: 250,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      fixed: 'left',
      width: 250,
      render: (size: any) => prettyBytes(size),
    },
    {
      title: 'Type',
      dataIndex: 'name',
      key: 'type',
      fixed: 'left',
      width: 250,
      render: (val: string) => val.substring(val.indexOf('.')),
    },
  ]

  const handleSubmit = () => {
    //fl
    BackendService.updateProjectsAttributeslite(projId, name, 'processing', filecount)
    //fl
    BackendService.updateRequest(projId, reqId, 'processing', filecount, uploadType, '', '', [])
    setstep(2)
    setProcess(true)
    setFiles([])
    setLoading(true)
    setTimeout(() => {
      BackendService.getRequest(projId, reqId, uploadType).then(data => {
        setdataReq(data)
        setProcess(false)
      })
      setLoading(false)
    }, 5000)
    setUploadFileCount(0)
  }

  const handleClose = () => {
    setLoading(true)
    setIsModalVisible(false)
    fetchTableData()
    setstep(0)
    uppy.cancelAll()
    setdataReq({
      status: '',
      filecount: 0,
      fileprocessing: 0,
      filecompleted: 0,
      fileerrored: 0,
    })
    setFiles([])
    setUploadProgress(0)
    setIsUploading(false)
    setUploadFileCount(0)
  }
  const handleCancel = () => {
    //fl
    BackendService.updateRequest(projId, reqId, 'cancel', filecount, uploadType, '', '', [])
    setFiles([])
    setIsModalVisible(false)
    setstep(0)
    uppy.cancelAll()
    setUploadProgress(0)
    setIsUploading(false)
    setIsAdded(false)
    setFilecount(0)
    setUploadFileCount(0)
  }
  const handleOk = () => {
    setLoading(true)
    fetchTableData()
    setIsModalVisible(false)
    setstep(0)
    fetchCount()
    uppy.cancelAll()
    setdataReq({
      status: '',
      filecount: 0,
      fileprocessing: 0,
      filecompleted: 0,
      fileerrored: 0,
    })
    setFiles([])
    setUploadProgress(0)
    setIsUploading(false)
  }
  const fetchCount = async () => {
    await BackendService.getProjectTotals(projId)
      .then(async res => {
        dispatch(setTotalCountData(res))
      })
      .catch(() => {
        // console.log('error in catch')
      })
  }
  const fetchData = () => {
    BackendService.getRequest(projId, reqId, uploadType).then(data => {
      setdataReq(data)
    })
  }
  useEffect(() => {
    const interval = setInterval(() => fetchData(), 5000)

    if (dataReq.status === 'complete') {
      clearInterval(interval)
    }
    if (dataReq.status === 'error') {
      clearInterval(interval)
    }
    if (reqId === '') {
      clearInterval(interval)
    }
    if (isModalVisible === false) {
      clearInterval(interval)
    }
    return () => {
      clearInterval(interval)
    }
  }, [dataReq])

  const getProgressFileCount = (pid: any, rid: any, len: number) => {
    // console.log(' UploadSetup ~ fileList', fileList)
    let l = len
    if (filecount) {
      l = filecount
    } else {
      l = len
    }

    BackendService.getUploadedFilecount('cmsfiles173752-staging', pid, rid).then(res => {
      if (res >= l) {
        setstep(1)
      }
      setUploadFileCount(res)
    })
  }
  const compressedUpload = (fls: any, pid: any, rid: any) => {
    setIsUploading(true)

    setFilecount(fls.length)
    // setFileList(FilteredFiles)
    zipTargetFiles(fls, uploadType)?.then((fl: any) => {
      try {
        const result: any = Storage.put(pid + `/${rid}/` + 'file.zip', fl, {
          level: 'public',
          progressCallback(progress: any) {
            setUploadProgress(Math.round((progress.loaded / progress.total) * 100))
          },
        }).then((res: any) => {
          getProgressFileCount(pid, rid, fls.length)
        })
      } catch (err) {
        // console.log('ERROR in upload', err)
      }
    })
  }
  useEffect(() => {
    const interval = setInterval(() => getProgressFileCount(projId, reqId, filecount), 5000)
    if (step === 1) {
      clearInterval(interval)
    }
    // if (isUploading == false) {
    //   clearInterval(interval)
    // }
    if (uploadFileCount >= files.length) {
      clearInterval(interval)
    }
    return () => {
      clearInterval(interval)
    }
  }, [uploadFileCount])
  const UploadFiles = (
    <>
      {isUploading == true ? (
        uploadProgress == 0 ? (
          <Result
            icon={<LoadingOutlined spin />}
            title={<h4 style={{ color: '#FFA500' }}>{`Preparing to upload.. `} </h4>}
          />
        ) : uploadProgress == 100 ? (
          <Result
            icon={<LoadingOutlined spin />}
            title={
              // fl
              <h4
                style={{ color: '#FFA500' }}
              >{`Validating Upload ${uploadFileCount}/${files.length} `}</h4>
            }
          />
        ) : (
          <Result
            icon={
              <Progress
                type='circle'
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
                percent={uploadProgress}
              />
            }
            title={<h4 style={{ color: '#FFA500' }}>{`Uploading ..`}</h4>}
          />
        )
      ) : (
        <>
          <Row justify='center'>
            {isAdded && (
              <Col span={15} style={{ marginBottom: 10 }}>
                <Alert
                  message={`${filecount} files are Selected to upload . Click Upload to start upload`}
                  // description='Click Upload to start upload'
                  type='info'
                />
              </Col>
            )}
          </Row>
          <Card style={{ maxHeight: 300, overflow: 'auto' }}>
            {/* <UppyModal isModalOpen={isUppyOpen} setModalOpen={setUppyOpen} /> */}

            {/* <UppyDash setFiles={setFiles} uppy={uppy} /> */}
          </Card>
        </>
      )}
    </>
  )

  const UploadedLists = (
    <div>
      <br />
      <Row justify='center'>
        <Col span={18}>
          <Space>
            <Typography>
              <b>Project Name</b>
            </Typography>
            <Input
              placeholder={name}
              style={{ width: '350px' }}
              defaultValue={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </Space>
        </Col>
      </Row>
      {/* fl */}
      <Table
        // showHeader={false}
        dataSource={files}
        columns={fileCols}
        rowKey='name'
        size='small'
        pagination={{ position: ['bottomCenter'], total: files.length }}
        style={{ marginTop: 15 }}
      />
    </div>
  )
  const status = (
    <>
      <Skeleton loading={process} avatar active>
        {dataReq.status === 'complete' ? (
          <>
            <Result
              icon={<CheckOutlined style={{ fontSize: 30, color: '#3f8600' }} />}
              title={<h4 style={{ color: '#3f8600' }}>Process Completed</h4>}
              // extra={<Button type='primary'>Next</Button>}
            />
          </>
        ) : (
          <>
            <Result
              icon={<LoadingOutlined spin />}
              title={
                <h4 style={{ color: '#FFA500' }}>
                  {dataReq.filecount === 1
                    ? `${dataReq.status} 1 file`
                    : `${dataReq.status}  files`}
                </h4>
              }
            />
          </>
        )}
      </Skeleton>
    </>
  )

  const createProj = (fls: any) => {
    const projName = `Project-${new Date().toLocaleDateString()}`
    setName(projName)
    BackendService.createProjectLite(projName, 'initial')
      .then(data => {
        setProjId(data.projectid)
        BackendService.createRequest(data.projectid, 'PSA').then((res: any) => {
          setReqId(res.requestid)
          compressedUpload(fls, data.projectid, res.requestid)
        })
      })
      .catch(err => {
        console.log('error', err)
        // setloading(false)
      })
  }

  const determineContent = (step: number) => {
    if (step === 0) return UploadFiles
    if (step == 1) return UploadedLists
    if (step == 2) return status
  }

  const { Title } = Typography

  return (
    <>
      {isSup?.isDash ? (
        <>
          <Superset />
        </>
      ) : (
        <>
          <Row gutter={[16, 16]} align='middle'>
            <Col span={24}></Col>
          </Row>
          <Row gutter={[16, 16]} align='middle'>
            <Col span={24}>
              <Title level={3}>Upload Files</Title>
            </Col>
            <Col span={12}>
              <div style={{ overflow: '200px' }}>
                {isModalVisible ? null : <UppyDash uppy={uppy} />}
              </div>
            </Col>
          </Row>
          <Modal
            title={'Upload Schematic Files'}
            style={{ top: 20, zIndex: 999999999 }}
            visible={isModalVisible}
            width={800}
            maskClosable={false}
            closable={false}
            keyboard={false}
            footer={[
              // <Row className={styles.rightBut} key={}>
              //   {
              step < 2 && (
                <Button style={{ margin: '0 8px' }} onClick={() => handleCancel()}>
                  Cancel
                </Button>
              ),
              step === 1 && (
                <Button type='primary' onClick={() => handleSubmit()}>
                  Submit
                </Button>
              ),
              step === 0 && (
                <Button
                  type='primary'
                  onClick={() => {
                    // compressedUpload()
                    setIsAdded(false)
                  }}
                  loading={isUploading ? true : false}
                  disabled={files.length ? false : true}
                >
                  {isUploading ? 'Uploading..' : 'Upload'}
                </Button>
              ),
              step === 2 && (
                <>
                  {dataReq.status === 'complete' ? (
                    <Button type='primary' onClick={() => handleOk()}>
                      OK
                    </Button>
                  ) : (
                    <>
                      <Button type='link' onClick={() => handleClose()}>
                        Close and Check status later
                      </Button>
                      <Button type='primary' loading={true}>
                        Please Wait
                      </Button>
                    </>
                  )}
                </>
              ),
            ]}
          >
            <Row className={styles.row}>
              <Col span={24}>
                <Steps type='navigation' current={step}>
                  <Step title={'Upload'} disabled={loading} />
                  <Step title={'Review & Submit'} disabled={loading} />
                  <Step title={'Upload Status'} disabled={loading} />
                </Steps>
              </Col>
            </Row>
            <Row className={styles.row}>
              <Col span={24}>{determineContent(step)}</Col>
            </Row>
          </Modal>

          <Col span={24}>
            <UploadliteTable
              projId={projId}
              tableData={tableLiteData}
              setData={setTableLiteData}
              fetchTableData={fetchTableData}
              loading={loading}
              setLoading={setLoading}
              // setisSup={setisSup}
            />
          </Col>
        </>
      )}
    </>
  )
}

export default UploadSetup
