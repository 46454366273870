import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Layout, Menu, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../assets/logo-no-text.png'
import PrivateHeader from '../PrivateHeader/PrivateHeader'
import PublicHeader from '../PublicHeader/PublicHeader'
import DefaultFooter from '../Footer/DefaultFooter'
import EmailSupport from '../../components/TechSupport/EmailSupport'
import styles from './BasicLayout.module.scss'
import { RootState } from '../../@types'
import Auth from '@aws-amplify/auth'
import { LeftOutlined } from '@ant-design/icons'

const { Sider, Content } = Layout
const { Footer } = Layout
const { SubMenu } = Menu

const MyAccountLayout: React.FC = props => {
  const history = useHistory()
  const { t } = useTranslation()
  const cognitoUser = useSelector((state: RootState) => state.auth.cognitoUser)
  const selectMenu = history.location.pathname.split('/')[1]
  // const [userType, setUserType] = useState('standard')
  // const GetadminUser = async () => {
  //   const user = await Auth.currentAuthenticatedUser()
  //   console.log('🚀 ~ file: MyAccount.tsx ~ line 29 ~ GetadminUser ~ user', user)
  //   // console.log(
  //   //   `user.signInUserSession.accessToken?.payload['cognito:groups'][0]`,
  //   //   user.signInUserSession.accessToken?.payload['cognito:groups'][0]
  //   // )
  //   if (user.signInUserSession?.accessToken?.payload?.['cognito:groups'][0] == undefined) {
  //     setUserType('standard')
  //   } else {
  //     setUserType(user.signInUserSession?.accessToken?.payload['cognito:groups'][0])
  //   }
  // }
  // useEffect(() => {
  //   GetadminUser()
  // }, [])
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='description' content={t('user.logo.welcome')} />
        <title>{t('sider.company')}</title>
      </Helmet>
      <Layout>
        <EmailSupport />
        <Sider width={250} className={styles.sider}>
          <Row className={styles.title} align='middle' justify='center'>
            <img alt='logo' className={styles.logo} src={logo} />
            <div>
              <h1>{t('sider.company')}</h1>
              <h4>{t('sider.edition')}</h4>
            </div>
          </Row>
          <Menu
            theme='dark'
            mode='inline'
            selectedKeys={['changepassword']}
            defaultOpenKeys={['settings']}
            onSelect={({ key }) =>
              key === 'changepassword'
                ? history.push('/myaccount')
                : key === 'changename'
                ? history.push('/changename')
                : history.push('/')
            }
          >
            <SubMenu key='settings' title='Settings'>
              {/* <Menu.Item key='changename' className={styles.menuItem}>
                {'Change Name'}
              </Menu.Item> */}
              <Menu.Item key='changepassword' className={styles.menuItem}>
                {'User Settings'}
              </Menu.Item>
            </SubMenu>

            {/* <Menu.Item
              key='dashboard'
              // className={styles.menuItem}
              style={{
                position: 'absolute',
                bottom: 0,
                zIndex: 1,
                backgroundColor: 'green',
              }}
            >
              <LeftOutlined /> Back to Home
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Layout className={styles.main}>
          {cognitoUser ? <PrivateHeader /> : <PublicHeader />}
          <Content className={styles.content}>{props.children}</Content>
          <Footer className={styles.footer}>
            <DefaultFooter />
          </Footer>
        </Layout>
      </Layout>
    </>
  )
}

export default MyAccountLayout
