import { Retailer, IRetailerState, SetRetailerDataAction, RetailerActionTypes } from '../../@types'
import * as actionTypes from '../action/actionTypes'

const initialState: IRetailerState = null

interface IPlanogramReducer<A> {
  (state: IRetailerState, action: A): IRetailerState
}

const setRetailerData: IPlanogramReducer<SetRetailerDataAction> = (state, action) => {
  return {
    ...state,
    ...action.data,
  } as Retailer
}

const reducer = (
  state: IRetailerState = initialState,
  action: RetailerActionTypes
): IRetailerState => {
  switch (action.type) {
    case actionTypes.SET_RETAILER:
      return setRetailerData(state, action)
    default:
      return state
  }
}

export default reducer
