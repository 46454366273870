import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useHistory, Link } from 'react-router-dom'
import { Form, Button, Input, Row, Checkbox, notification } from 'antd'
import { SignIn } from '../../../services'
import { setCognitoUser } from '../../../store/action/index'
import styles from './style.module.scss'
import { Auth } from 'aws-amplify'
import { completeNewPassword, updateUser } from '../../../services'
import { Popover, Progress } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { Rule } from 'antd/lib/form'

const FormItem = Form.Item
type LoginType = {
  mail: string
  password: string
  FamilyName: string
  GivenName: string
}
type LocationType = {
  username: string
  password: string
  cognitoUser: any
}
type Function = {
  username: string
  code: string
  newPassword: string
}

const NewPassword = () => {
  const location = useLocation<LocationType>()
  const [visible, setvisible] = useState(false)
  const [popover, setpopover] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const [form] = Form.useForm()
  const [check, setcheck] = useState(true)
  const [loading, setloading] = useState(false)
  const username = location.state.username
  const password = location.state.password

  const cognitoUser = location.state.cognitoUser
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    Auth.signIn(username, password).then(results => {
      setCurrentUser(results)
    })
  }, [])
  const onFinish = async (values: LoginType) => {
    // const currentUser = await Auth.currentAuthenticatedUser()
    // console.log('current user', currentUser)
    completeNewPassword(
      currentUser, // the Cognito User Object
      values.password,
      // the new password
      // OPTIONAL, the required attributes
      {
        family_name: values.FamilyName,
        given_name: values.GivenName,
      }
    )
      .then(user => {
        history.push({
          pathname: '/user/login',
        })
        // console.log(user)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const passwordLengthValidator = (rule: Rule, value: string) => {
    const promise = Promise
    if (!value) {
      setvisible(!!value)
      return promise.reject(t('user.required.password'))
    }
    // 有值的情况
    if (!visible) {
      setvisible(!!value)
    }
    setpopover(!popover)
    if (value.length < 8) return promise.reject('')
    return promise.resolve()
  }

  const passwordCapLetterValidator = (rule: Rule, value: string) => {
    const promise = Promise
    setpopover(!popover)
    if (value === value.toLowerCase()) return promise.reject('')
    return promise.resolve()
  }

  const passwordLowLetterValidator = (rule: Rule, value: string) => {
    const promise = Promise
    setpopover(!popover)
    if (value === value.toUpperCase()) return promise.reject('')
    return promise.resolve()
  }

  const passwordNumberValidator = (rule: Rule, value: string) => {
    const promise = Promise
    setpopover(!popover)
    if (/\d/.test(value)) return promise.resolve()
    return promise.reject('')
  }

  const checkPsswordLength = () => {
    const password = form.getFieldValue('password')
    if (password.length < 8) return false
    return true
  }

  const checkPsswordCapLetter = () => {
    const password = form.getFieldValue('password')
    if (password === password.toLowerCase()) return false
    return true
  }

  const checkPsswordLowLetter = () => {
    const password = form.getFieldValue('password')
    if (password === password.toUpperCase()) return false
    return true
  }

  const checkPsswordNumber = () => {
    const password = form.getFieldValue('password')
    return /\d/.test(password)
  }

  const getPasswordStatus = () => {
    const password = form.getFieldValue('password')
    if (
      checkPsswordLength() &&
      checkPsswordCapLetter() &&
      checkPsswordLowLetter() &&
      checkPsswordNumber() &&
      password.length > 11
    )
      return 'success'
    if (
      checkPsswordLength() &&
      checkPsswordCapLetter() &&
      checkPsswordLowLetter() &&
      checkPsswordNumber()
    )
      return 'normal'
    return 'exception'
  }

  const passwordStatusMap = {
    success: <div className={styles.success}>{t('user.strength.strong')}</div>,
    normal: <div className={styles.warning}>{t('user.strength.medium')}</div>,
    exception: <div className={styles.error}>{t('user.strength.invalid')}</div>,
  }

  const renderPasswordProgress = () => {
    const password = form.getFieldValue('password')
    const passwordStatus = getPasswordStatus()
    return password && password.length ? (
      <div>
        <Progress
          status={passwordStatus}
          className={styles.progress}
          strokeWidth={8}
          percent={passwordStatus === 'exception' ? 0 : passwordStatus === 'normal' ? 50 : 100}
          showInfo={false}
        />
      </div>
    ) : null
  }

  const genPopoverContent = () =>
    visible && (
      <div className={styles.popover}>
        {passwordStatusMap[getPasswordStatus()]}
        {renderPasswordProgress()}
        <div className={styles.popoverContent}>
          <Row align='middle'>
            {checkPsswordLength() ? (
              <CheckCircleTwoTone className={styles.popoverIcon} twoToneColor='#52c41a' />
            ) : (
              <CloseCircleTwoTone className={styles.popoverIcon} twoToneColor='#f5222d' />
            )}
            {t('user.hint.length')}
          </Row>
          <Row align='middle'>
            {checkPsswordLowLetter() ? (
              <CheckCircleTwoTone className={styles.popoverIcon} twoToneColor='#52c41a' />
            ) : (
              <CloseCircleTwoTone className={styles.popoverIcon} twoToneColor='#f5222d' />
            )}
            {t('user.hint.lowLetter')}
          </Row>
          <Row align='middle'>
            {checkPsswordCapLetter() ? (
              <CheckCircleTwoTone className={styles.popoverIcon} twoToneColor='#52c41a' />
            ) : (
              <CloseCircleTwoTone className={styles.popoverIcon} twoToneColor='#f5222d' />
            )}
            {t('user.hint.capLetter')}
          </Row>
          <Row align='middle'>
            {checkPsswordNumber() ? (
              <CheckCircleTwoTone className={styles.popoverIcon} twoToneColor='#52c41a' />
            ) : (
              <CloseCircleTwoTone className={styles.popoverIcon} twoToneColor='#f5222d' />
            )}
            {t('user.hint.number')}
          </Row>
        </div>
      </div>
    )
  return (
    <div className={styles.main}>
      <h2>New Password</h2>
      <Form form={form} name='Newpassword' onFinish={onFinish}>
        <Row>Password</Row>
        <Popover
          content={genPopoverContent}
          overlayStyle={{ width: 300 }}
          placement='right'
          visible={visible}
        >
          <FormItem
            name='password'
            className={styles.password}
            rules={[
              {
                validator: passwordLengthValidator,
              },
              {
                validator: passwordCapLetterValidator,
              },
              {
                validator: passwordLowLetterValidator,
              },
              {
                validator: passwordNumberValidator,
              },
            ]}
          >
            <Input.Password
              size='large'
              type='password'
              placeholder={t('user.required.password')}
            />

            {/* <Input size='large' type='password' placeholder={t('user.required.password')} /> */}
          </FormItem>
        </Popover>
        {/* <Row>First name</Row>

        <FormItem
          name='FamilyName'
          rules={[
            {
              required: true,
              message: 'First Name Required',
            },
          ]}
        >
          <Input size='large' type='text' placeholder={'First Name'} />
        </FormItem>

        <Row>Second Name</Row>
        <FormItem
          name='GivenName'
          rules={[
            {
              required: true,
              message: 'Second Name Required',
            },
          ]}
        >
          <Input size='large' type='text' placeholder={'Second Name'} />
        </FormItem> */}

        <FormItem className={styles.submit}>
          <Button
            size='large'
            loading={loading}
            className={styles.submit}
            type='primary'
            htmlType='submit'
          >
            Submit
          </Button>
        </FormItem>
        <Link className={styles.floatright} to='/user/login'>
          Bact to Login Page
        </Link>
      </Form>
    </div>
  )
}

export default NewPassword
