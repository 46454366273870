import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import { StringGradients } from 'antd/lib/progress/progress'
// import { StringDecoder } from 'node:string_decoder'
import { ProjectPlanogram, PlanogramTable, SubmitFilter, FormDataType } from '../@types'
/*
export interface Project {
  projectid: string
  projName: string
  user: string
  projHierarchy: string
}
*/
const apiName = 'blob-images'
const apiName2 = 'reveal2'
const revealnext2 = 'revealnext2'
async function authHeaders() {
  const token = await getAuthToken()
  return {
    Authorization: 'Bearer '.concat(token),
  }
}

async function getAuthToken() {
  const session = await Auth.currentSession()
  const idToken = session.getIdToken()
  return idToken.getJwtToken()
}

async function fetchProjects(ownerid: string): Promise<Array<PlanogramTable>> {
  const active = 'active'
  return await API.get(apiName, `listprojects/${ownerid}`, {})
}
async function fetchProjectsBase(ownerid: string, base: any): Promise<Array<PlanogramTable>> {
  // console.log(ownerid.replace(/\//g,'_').replace(/ +/g, ""),"1111111111111")
  const active = 'active'
  return await API.get(apiName, `listprojectsbybase/${ownerid.replace(/\//g,'_')}/${base}`, {})
}
async function fetchArchiveProjects(ownerid: string): Promise<Array<PlanogramTable>> {
  const archive = 'archive'
  return await API.get(apiName, `listprojects/${ownerid}/${archive}`, {})
}
async function listProjectsLite(): Promise<Array<PlanogramTable>> {
  return await API.get(revealnext2, `listprojectslite`, {})
}
async function getProjectLite(projectid: string): Promise<Array<PlanogramTable>> {
  return await API.get(revealnext2, `getprojectlite/${projectid}`, {})
}
async function listReports() {
  return await API.get(apiName, 'listreports', {})
}
async function listProjectsV2(ownerid: string, hierarchy?: string) {
  const lastEvaluatedKey = ''
  return await API.post(apiName, 'listprojectsv2', {
    body: {
      ownerid,
      lastEvaluatedKey,
      hierarchy,
    },
  })
}
async function makeArchive(projid: string) {
  const setstatus = 'Archive'
  return await API.post(apiName, 'updateprojstatus', {
    body: {
      projid,
      setstatus,
    },
  })
}
async function makeActive(projid: string) {
  const setstatus = 'Active'
  return await API.post(apiName, 'updateprojstatus', {
    body: {
      projid,
      setstatus,
    },
  })
}

async function createRetailer(name: string, storeExtraction: number, productKey: string) {
  return await API.post(apiName, 'createretailer', {
    timeout: 60000,
    body: {
      name,
      storeExtraction,
      productKey,
    },
  })
}

async function getRetailers() {
  return await API.get(apiName, `getretailers`, {})
}
async function createProject(
  projName: string,
  projTime: string,
  // prdkey: string,
  retailID: string,
  baseCallPoints: any,
  multiBase: boolean,
  slkey: any,
  setStatus: string
) {
  return await API.post(apiName, 'createproject', {
    timeout: 60000,
    body: {
      projName,
      projTime,
      // prdkey,
      retailID,
      baseCallPoints,
      multiBase,
      slkey,
      setStatus,
    },
  })
}
async function createProjectLite(projName: string, projStatus: string) {
  return await API.post(revealnext2, 'createprojectlite', {
    timeout: 60000,
    body: {
      projName,
      projStatus,
    },
  })
}

async function getProjectSingle(projectid: string) {
  return await API.get(apiName, `getproject/${projectid}`, {})
}
async function getRetailer(retailerid: string) {
  return await API.get(apiName, `getretailer/${retailerid}`, {})
}
async function getDashboardsData(projectid: string, reportid: string, filename: string) {
  return await API.get(apiName, `getdashboardsdata/${projectid}/${reportid}/${filename}`, {})
}
async function createUploadFile(projectid: string, filelist: string[], filetype: string) {
  return await API.post(apiName, 'createprojectupload', {
    body: {
      projectid,
      filelist,
      filetype,
    },
  })
}
async function getProjectUpload(projectid: string, filetype: string) {
  return await API.post(apiName, `getprojectupload/${projectid}`, {
    body: {
      filetype,
    },
  })
}
async function getProjectRequest(projectid: string, filetype: string) {
  return await API.get(apiName, `getprojectrequest/${projectid}/${filetype}`, {})
}
async function getAllProjectRequest(projectid: string) {
  return await API.get(apiName, `getallprojectrequest/${projectid}`, {})
}
async function getAllProjectRequestLite(projectid: string) {
  return await API.get(apiName, `getallprojectrequestlite/${projectid}`, {})
}
async function getProjectPlanograms(projectid: string) {
  return await API.get(apiName, `projectplanogramsv1/${projectid}`, {})
}
async function getProjectProducts(projectid: string) {
  return await API.post(apiName, `projectproducts/${projectid}`, {})
}
async function getProjectStores(projectid: string) {
  return await API.get(apiName, `projectstores/${projectid}`, {})
}
async function getProjectSameStores(projectid: string, compareid: string) {
  return await API.get(apiName, `samestores/${projectid}/${compareid}`, {})
}
async function getSavedComparisonFilter(projectid: string) {
  return await API.get(apiName, `compareprojectreportfilters/${projectid}`, {})
}
async function listProjectBase(projectid: string) {
  return await API.get(apiName, `listprojectsbybase/${projectid}`, {})
}
async function getSpaceAnalysis(projectid: string, reqid: string, file: string, segid: string) {
  return await API.get(apiName, `getspaceanalysisv1/${projectid}/${reqid}/${file}/${segid}`, {})
}
async function getSpaceAnalysisByLevel(
  projectid: string,
  reqid: string,
  file: string,
  segment: string,
  level: string,
  path: string
) {
  return await API.get(
    revealnext2,
    `getspaceanalysisbylevel/${projectid}/${reqid}/${file}/${segment}/${level}/${path}`,
    {}
  )
}
async function createRequest(
  projectid: string,
  filetype: string
  // isCompare?: boolean,
  // comparedProject?: string
) {
  return await API.post(apiName, `createrequest`, {
    body: {
      filetype,
      projectid,
      // isCompare,
      // comparedProject,
    },
  })
}
async function updateRequest(
  projectid: string,
  requestid: string,
  requeststatus: string,
  filecount: number,
  filetype: string,
  filename: string,
  reqflow?: string,
  formData?: FormDataType[],
  compareid?: string,
  isCompare?: boolean,
  comparedProject?: string
) {
  return await API.post(apiName, `updaterequest`, {
    body: {
      projectid,
      requestid,
      requeststatus,
      filecount,
      filetype,
      filename,
      requestflow: reqflow,
      basecallpoints: formData,
      compareid,
      isCompare,
      comparedProject,
    },
  })
}
async function updateProjectsAttributeslite(
  projectid: string,
  projectName: string,
  status?: string,
  fileCount?: number
) {
  return await API.post(revealnext2, `updateprojectsattributeslite`, {
    body: { projectid, projectName, projectStatus: status, fileCount },
  })
}
async function getValidation(projectid: string) {
  return await API.get(apiName, `getvalidation/${projectid}`, {})
}
async function getRequest(projectid: string, requestid: string, filetype: string) {
  return await API.get(apiName, `getrequest/${projectid}/${requestid}/${filetype}`, {})
}
async function submitProject(projectid: string, ownerid: string) {
  return await API.post(apiName, `submitproject/${ownerid}/${projectid}`, {})
}
async function getUploadedProducts(projectid: string) {
  return await API.get(apiName, `uploadedproducts/${projectid}`, {})
}
async function getUploadedStores(projectid: string) {
  return await API.get(apiName, `uploadedstores/${projectid}`, {})
}
async function CreateCompareReport(reportName: string, projId: any, compareProjId: string) {
  return await API.post(apiName, 'createcomparereport', {
    body: {
      reportName,
      projId,
      compareProjId,
    },
  })
}
async function getChartData(projectid: string) {
  return await API.get(apiName, `getlinear/${projectid}`, {})
}
async function getFilesCompleted(projectid: string, requestid: string) {
  return await API.get(apiName, `getrequestfiles/${projectid}/${requestid}/pogextract`, {})
}
async function getFilesErorred(projectid: string, requestid: string) {
  return await API.get(apiName, `getrequestfiles/${projectid}/${requestid}/error`, {})
}
async function getProjectReports(projectid: string) {
  return await API.get(apiName, `getprojectreports/${projectid}`, {})
}
async function CreateReportRequest(projectid: string, reporttype: string, reportname: string) {
  return await API.post(apiName, 'createreportrequest', {
    body: {
      projectid,
      reporttype,
      reportname,
    },
  })
}

async function getProductSummary(projectid: string) {
  return await API.get(apiName, `projectproductsummary/${projectid}`, {})
}
async function getProductSummaryv1(projectid: string, segment: string) {
  return await API.get(apiName, `projectproductsummaryv1/${projectid}/${segment}`, {})
}

async function getPlanogramSummary(projectid: string) {
  return await API.get(apiName, `projectplanogramsummary/${projectid}`, {})
}
async function getProjectTotals(projectid: string) {
  return await API.get(apiName, `projecttotals/${projectid}`, {})
}
async function getShareofCategory(projectid: string) {
  return await API.get(apiName, `getshareof/${projectid}`, {})
}
async function getShareof(projectid: string, reportid: string, seg: string, measure: string) {
  return await API.get(apiName, `getshareof/${projectid}/${reportid}/${seg}/${measure}`, {})
}
async function CreateReportData(projectid: string) {
  return await API.post(apiName, 'createreportdata', {
    body: {
      projectid,
    },
  })
}
async function getShareNew(
  reportid: string,
  reqid: string,
  file: any,
  seg: string,
  measure: string
) {
  return await API.get(apiName, `getshareofv1/${reportid}/${reqid}/${file}/${seg}/${measure}`, {})
}
async function getValidationMessage(projectid: string, filename: string) {
  return await API.get(apiName, `getvalidationmessage/${projectid}/${filename}`, {})
}
async function getShare(reportid: string, seg: string, measure: string) {
  return await API.get(apiName, `getshareof/${reportid}/${seg}/${measure}`, {})
}

async function getProjectValidation(projectid: string) {
  return await API.get(apiName, `getprojectvalidation/${projectid}`, {})
}
async function SubmitProject(ownerid: string, projectid: string) {
  return await API.post(apiName, `submitproject/${ownerid}/${projectid}`, {})
}
async function submitStatus(projectid: string) {
  return await API.get(apiName, `submitstatus/${projectid}`, {})
}
async function getSpacetosales(projectid: string, seg: string, space: string, sales: string) {
  return await API.get(apiName, `getspacetosales/${projectid}/${seg}/${space}/${sales}`, {})
}
async function getSpacetosalesfilter(
  projectid: string,
  reqid: string,
  file: string,
  seg: string,
  space: string,
  sales: string
) {
  return await API.get(
    apiName,
    `getspacetosalesv1/${projectid}/${reqid}/${file}/${seg}/${space}/${sales}`,
    {}
  )
}
async function getSpacetosalescategory(
  projectid: string,
  reqid: string,
  file: string,
  seg: string,
  space: string,
  sales: string,
  level: string,
  path: string
) {
  return await API.get(
    revealnext2,
    `getspaccetosalescategory/${projectid}/${reqid}/${file}/${seg}/${space}/${sales}/${level}/${path}`,
    {}
  )
}

async function getDaysofSupply(projectid: string, seg: string) {
  return await API.get(apiName, `getdaysofsupply/${projectid}/${seg}`, {})
}
async function getDaysofSupplyfilter(projectid: string, reqid: string, file: string, seg: string) {
  return await API.get(apiName, `getdaysofsupplyv1/${projectid}/${reqid}/${file}/${seg}`, {})
}
async function getUploadedFilecount(bucket: string, projectid: string, requestid: string) {
  return await API.get(apiName, `getuploadedfilecount/${bucket}/${projectid}/${requestid}`, {})
}
async function getExtractFile(projectid: string) {
  return await API.get(apiName, `getextractfile/${projectid}`, {})
}
async function getFilter1(projectid: string) {
  return await API.get(apiName, `getprojectfilter1/${projectid}`, {})
}
async function getFilter2(projectid: string, filter1: string) {
  return await API.get(apiName, `getprojectfilter2/${projectid}/${filter1}`, {})
}
async function getProductsByFilter(projectid: string, type: string, name: string) {
  const encode = encodeURIComponent(name)
  return await API.get(apiName, `projectproductsbyfilter/${projectid}/${type}/${encode}`, {})
}
async function getProjectCompareReports(projectid: string) {
  return await API.get(apiName, `projectcomparereports/${projectid}`, {})
}
async function getProductFilter(projectid: string, type: string) {
  return await API.get(apiName, `projectproducthierarchy/${projectid}/${type}`, {})
}
async function getPlanogramFilter(projectid: string, type: string) {
  return await API.get(apiName, `projectplanogramhierarchy/${projectid}/${type}`, {})
}
async function getProjectStoreHierarchy(projectid: string, type: string) {
  return await API.get(apiName, `projectstorehierarchy/${projectid}/${type}`, {})
}
async function getStoreFilter(projectid: string) {
  return await API.get(apiName, `getstorelist/${projectid}`, {})
}
async function getStoreUpcData(projectid: string, storenumber: string) {
  return await API.get(apiName, `getstoreupcdata/${projectid}/${storenumber}`, {})
}
async function CreateReportFilter(
  projectid: string,
  filters: string[],
  filterName: string,
  filterType: string,
  filterSave: number
) {
  return await API.post(apiName, 'createreportfilter', {
    body: {
      projectid,
      filters,
      filterName,
      filterType,
      filterSave,
    },
  })
}
async function CreateComaprisonReportFilter(
  projectid: string,
  compareid: string,
  filters: string[],
  filterName: string,
  filterType: string,
  filterSave: number
) {
  return await API.post(apiName, 'createcomparisonreportfilter', {
    body: {
      projectid,
      compareid,
      filters,
      filterName,
      filterType,
      filterSave,
    },
  })
}
async function getUpcPlanogramData(projectid: string, upc: number) {
  return await API.get(apiName, `getupcpogstoredata/${projectid}/${upc}`, {})
}
async function getUpcStoreData(projectid: string, upc: number) {
  return await API.get(apiName, `getupcstoredata/${projectid}/${upc}`, {})
}
// async function getRetailers() {
//   return await API.get(apiName, `getretailers`, {})
// }
async function getPdffileUrl(projectid: string, filename: string) {
  return await API.post(apiName, 'pdffileurl', {
    body: {
      projectid,
      filename,
    },
  })
}
async function getProjectReportFilters(projectid: string) {
  return await API.get(apiName, `projectreportfilters/${projectid}`, {})
}
async function getReportStoreHierarchy(projectid: string) {
  return await API.get(apiName, `reportstorehierarchy/${projectid}`, {})
}
async function getBaseCallPoint(reqid: string) {
  return await API.get(apiName, `getbasecallpoints/${reqid}`, {})
}
async function getRetailerNames(reqid: string) {
  return await API.get(apiName, `getretailernames/${reqid}`, {})
}
async function getReportProductHierarchy(projectid: string) {
  return await API.get(apiName, `reportproducthierarchy/${projectid}`, {})
}
async function getReportBrandHierarchy(projectid: string) {
  return await API.get(apiName, `reportbrandhierarchy/${projectid}`, {})
}
async function getReportPackageHierarchy(projectid: string) {
  return await API.get(apiName, `reportpackagehierarchy/${projectid}`, {})
}
async function getComparisonDist(projectid: string, reqid: string, file: string, segid: string) {
  return await API.get(apiName, `getcomparisondist/${projectid}/${reqid}/${file}/${segid}`, {})
}
async function getSpaceChange(projectid: string, file: string) {
  return await API.get(revealnext2, `getspacechange/${projectid}/${file}`, {})
}
async function getComparisonAssortment(
  projectid: string,
  reqid: string,
  file: string,
  segid: string
) {
  return await API.get(
    apiName,
    `getcomparisonassortment/${projectid}/${reqid}/${file}/${segid}`,
    {}
  )
}
async function getComparisonTotal(projectid: string, compareid: string) {
  return await API.get(apiName, `comparisontotal/${projectid}/${compareid}`, {})
}

async function getReportdata() {
  return await API.get(apiName, `getreportdata`, {})
}
async function getIndexeddb() {
  return await API.get(apiName, `getindexeddb`, {})
}
async function createPivotFilter(projectid: string, filename: string) {
  return await API.post(apiName2, 'createpivotfilter', {
    body: {
      projectid,
      filename,
    },
  })
}
async function getMultiFolderList(projectid: string, reqestid: string) {
  return await API.get(revealnext2, `getmultifolderlist/${projectid}/${reqestid}`, {})
}
async function getNotifications() {
  return await API.get(revealnext2, `getnotifications`, {})
}
async function updateNotifications() {
  return await API.post(revealnext2, `updatenotification`, {})
}

async function getUserList() {
  return await API.get(revealnext2, `userlist`, {})
}

async function createExcelReport(
  projectid: string,
  horizontalGrouping: string,
  storeFilter: string[],
  productFilter: string[],
  filterType: string
) {
  return await API.post(revealnext2, 'createexcelreport', {
    body: {
      projectid,
      horizontalGrouping,
      storeFilter,
      productFilter,
      filterType,
    },
  })
}
async function getTimePeriod() {
  return await API.get(revealnext2, `gettimeperiod`, {})
}
async function updateTimePeriod(
  type: string,
  timeperiod: string,
  isDefault: number,
  isActive: number,
  sortOrder: number,
  currentIndex: string,
  previousIndex: string
) {
  return await API.post(revealnext2, 'updatetimeperiod', {
    body: {
      type: type,
      timeperiod: timeperiod,
      isDefault: isDefault,
      isActive: isActive,
      sortOrder: sortOrder,
      currentIndex: currentIndex,
      previousIndex: previousIndex,
    },
  })
}
async function createUser(
  UserEmail: string,
  fname: string,
  lname: string,
  tempPwd: string,
  isAdmin: boolean,
  apps: string
) {
  return await API.post(revealnext2, 'createuser', {
    body: {
      UserEmail,
      fname,
      lname,
      tempPwd,
      isAdmin,
      apps,
    },
  })
}

async function updateUserStatus(userkey: string, userstatus: boolean) {
  return await API.post(revealnext2, 'updateuserstatus', {
    body: {
      userkey,
      userstatus,
    },
  })
}

async function updateNameOfUser(first_name: string, last_name: string, userkey?: string) {
  return await API.post(revealnext2, 'updatenameofuser', {
    body: {
      first_name,
      last_name,
      userkey,
    },
  })
}
async function updateUserType(addtoadmin: boolean, userkey?: string) {
  return await API.post(revealnext2, 'updateusertype', {
    body: {
      addtoadmin,
      userkey,
    },
  })
}
async function updateUserApps(apps: string, userkey?: string) {
  return await API.post(revealnext2, 'updateuserapps', {
    body: {
      apps,
      userkey,
    },
  })
}
async function getUserDetails() {
  return await API.get(revealnext2, `getuserdetails`, {})
}
async function getDashboardLite(id?: string) {
  return await API.post(revealnext2, `getdashboardlite`, {
    body: {
      id,
    },
  })
}
async function generateReportLink(projectid?: string, dashboardid?: string) {
  return await API.post(revealnext2, `sharelink`, {
    body: {
      projectId: projectid,
      dashboardId: dashboardid,
      action: 'create',
    },
  })
}
async function updateReportLink(projectid?: string, dashboardid?: string, isActive?: boolean) {
  return await API.post(revealnext2, `sharelink`, {
    body: {
      projectId: projectid,
      dashboardId: dashboardid,
      isActive: isActive,
      action: 'update',
    },
  })
}
async function listDashPod(projectid: string) {
  return await API.post(revealnext2, `listdashpod`, {
    body: {
      projectid,
    },
  })
}
async function getDashPod(projectid: string, requestid: string) {
  return await API.post(revealnext2, `getdashpod`, {
    body: {
      projectid,
      requestid,
    },
  })
}
async function listFilterProd(projectid: string, requestid: string) {
  return await API.get(revealnext2, `listfilterprod`, {})
}

async function createDashPod(projectid: string, ProductFilterType: string, products: any[]) {
  return await API.post(revealnext2, `createdashpod`, {
    body: {
      projectid,
      ProductFilterType,
      products,
    },
  })
}
async function createFilterPod(filtername: string, filtertype: string, filterdata: any[]) {
  return await API.post(revealnext2, `createfilterpod`, {
    body: {
      filtername,
      filtertype,
      filterdata,
    },
  })
}
const BackendService = {
  getUploadedStores,
  getPlanogramFilter,
  getRetailerNames,
  getUpcPlanogramData,
  getBaseCallPoint,
  getRetailers,
  getStoreUpcData,
  getUpcStoreData,
  getSpacetosales,
  getDashboardsData,
  getDaysofSupply,
  getStoreFilter,
  getProductSummary,
  getFilesErorred,
  getShare,
  getProductFilter,
  getShareNew,
  getProductsByFilter,
  getShareofCategory,
  getProjectTotals,
  getFilesCompleted,
  getSpacetosalesfilter,
  fetchProjects,
  fetchArchiveProjects,
  fetchProjectsBase,
  createProject,
  getFilter1,
  getFilter2,
  getProjectSingle,
  createUploadFile,
  getProjectUpload,
  getProjectPlanograms,
  getProjectProducts,
  getSavedComparisonFilter,
  getProjectRequest,
  getUploadedProducts,
  createRequest,
  getProjectStores,
  getProjectSameStores,
  updateRequest,
  getValidation,
  submitProject,
  listProjectsV2,
  CreateCompareReport,
  listReports,
  getChartData,
  getRequest,
  getProjectReports,
  CreateReportRequest,
  getSpaceAnalysis,
  getSpaceAnalysisByLevel,
  getPlanogramSummary,
  getShareof,
  CreateReportData,
  getValidationMessage,
  getDaysofSupplyfilter,
  getProjectValidation,
  SubmitProject,
  submitStatus,
  getUploadedFilecount,
  getExtractFile,
  getAllProjectRequest,
  getProductSummaryv1,
  getProjectCompareReports,
  getProjectStoreHierarchy,
  CreateReportFilter,
  CreateComaprisonReportFilter,
  createRetailer,
  getRetailer,
  makeArchive,
  makeActive,
  getPdffileUrl,
  getProjectReportFilters,
  getReportStoreHierarchy,
  getReportProductHierarchy,
  getReportBrandHierarchy,
  getReportPackageHierarchy,
  getComparisonDist,
  getComparisonAssortment,
  getComparisonTotal,
  getReportdata,
  getIndexeddb,
  createPivotFilter,
  getMultiFolderList,
  getUserList,
  createExcelReport,
  getNotifications,
  updateNotifications,
  getTimePeriod,
  updateTimePeriod,
  getSpaceChange,
  getSpacetosalescategory,
  updateUserStatus,
  createUser,
  updateNameOfUser,
  updateUserType,
  updateUserApps,
  getUserDetails,
  createProjectLite,
  getAllProjectRequestLite,
  listProjectsLite,
  getProjectLite,
  updateProjectsAttributeslite,
  getDashboardLite,
  generateReportLink,
  updateReportLink,
  listDashPod,
  getDashPod,
  createDashPod,
  createFilterPod,
  listFilterProd,
  listProjectBase,
}
export default BackendService
