import * as actionTypes from './actionTypes'
import { ThunkAction } from 'redux-thunk'
import { RootState, AdminUserActionTypes, AdminUser } from '../../@types'

type ThunkResult<R> = ThunkAction<R, RootState, undefined, AdminUserActionTypes>
interface IAction<P, T> {
  (param: P): ThunkResult<T>
}
export const setAdminUserData: IAction<Partial<AdminUser>, void> = data => dispatch => {
  dispatch({
    type: actionTypes.SET_ADMIN_USER,
    data: data,
  })
}
