import React from 'react'
import { Steps, Skeleton, Badge, Tooltip, Modal } from 'antd'
import {
  Table,
  Button,
  Row,
  Col,
  Card,
  Progress,
  Select,
  Typography,
  Switch,
  Input,
  message,
  Statistic,
} from 'antd'
import BackendService from '../../services/backend'
import Classes from './ReprotLinkDisplay.module.scss'
import { DisconnectOutlined, LinkOutlined, CopyOutlined } from '@ant-design/icons'
export default function ReportLinkDisplay(props) {
  React.useEffect(() => {
    if (props.isActive) {
      setisLinkActive(true)
    } else {
      setisLinkActive(false)
    }
  }, [props])

  const [isLinkActive, setisLinkActive] = React.useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  console.log(props)
  const { Text } = Typography
  const onChange = () => {
    const makeLink = isLinkActive ? false : true
    console.log(props.projectId, props.dashboardId, makeLink)
    BackendService.updateReportLink(props.projectidSliced, props.dashboardId, makeLink).then(
      res => {
        console.log(res)
        if (!res.isActive) {
          setisLinkActive(false)
        } else if (res.isActive) {
          setisLinkActive(true)
        }
      }
    )
  }
  const info = () => {
    messageApi.info('Link Copied')
  }
  const copyButton = (
    <Tooltip title={'Click to Copy'}>
      <span
        onClick={() => {
          navigator.clipboard.writeText(
            `https://report.revealform.com/${props.projectidSliced}?dash=${props.dashboardId}`
          )
          info()
        }}
      >
        {/* TODO: lacks visual feedback */}
        <CopyOutlined />
      </span>
    </Tooltip>
  )

  return (
    <>
      {contextHolder}
      <div className={Classes.mainWrapper}>
        <div className={Classes.switchWrapper}>
          <label>Allow access to the report with the secure link:</label>
          <Switch checked={isLinkActive} onChange={onChange} />
          <div className={Classes.viewCountWrapper}>
            <Statistic title='View Count' value={props.viewCount} />
          </div>
        </div>
        {isLinkActive ? (
          <div className={Classes.linkWrapper}>
            <Input
              addonAfter={copyButton}
              value={`https://report.revealform.com/${props.projectidSliced}?dash=${props.dashboardId}`}
              disabled={true}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}

/*
<p>
        {`https://report.revealform.com/${props.projectidSliced}?dash=${props.dashboardId}`} 
        </p>
         <span>
         <CopyOutlined /> 
         </span>


<div className={Classes.linkWrapper}>
        {props.projectId ? (
          <Text className='link'>
            {`https://report.revealform.com/${props.projectidSliced}?dash=${props.dashboardId}`}
          </Text>
        ) : (
          'Loading...'
        )}
      </div>
      <div className={Classes.actionWrapper}>
        <Button
          type='primary'
          shape='round'
          onClick={() =>
            navigator.clipboard.writeText(
              `https://report.revealform.com/${props.projectidSliced}?dash=${props.dashboardId}`
            )
          }
        >
          Copy Link
        </Button>
        <div className={Classes.switchLabels}>
          
        </div>
      </div>
*/
