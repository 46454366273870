import React from 'react'
import Super from './Super'

const index = () => {
  return (
    <div style={{ width: '100%' }} id='superset-container'>
      <Super />
    </div>
  )
}

export default index
