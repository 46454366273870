import * as actionTypes from './actionTypes'
import { ThunkAction } from 'redux-thunk'
import { RootState, RetailerActionTypes, Retailer } from '../../@types'

type ThunkResult<R> = ThunkAction<R, RootState, undefined, RetailerActionTypes>
interface IAction<P, T> {
  (param: P): ThunkResult<T>
}
export const setRetailerData: IAction<Partial<Retailer>, void> = data => dispatch => {
  dispatch({
    type: actionTypes.SET_RETAILER,
    data: data,
  })
}
