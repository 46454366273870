import * as actionTypes from './actionTypes'
import { ThunkAction } from 'redux-thunk'
import { RootState, FileName, FileNameActionTypes } from '../../@types'

type ThunkResult<R> = ThunkAction<R, RootState, undefined, FileNameActionTypes>
interface IAction<P, T> {
  (param: P): ThunkResult<T>
}
export const setFileNameData: IAction<Partial<FileName>, void> = data => dispatch => {
  dispatch({
    type: actionTypes.SET_FILENAME,
    data: data,
  })
}
